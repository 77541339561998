import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import "../File.css";
import "../Table.css";
import Appointment from "../Appointment/Appointment";
import vars from "../../utils/variables";

const MyAppointments = props => {
  const [api, setApi] = useState({
    futureAppointments: [],
    pastAppointments: []
  });

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/appointments/user`, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setApi(res);
        } else if (res.subject === "NotAuthenticated") {
          props.history.push("/login");
        } else if (res.subject === "NotAuthorized") {
          props.history.push("/notauthorized");
        }
      });
    }
    fetchData();
  }, [props]);

  return (
    <div className="medicalrecords">
      <h1>Persönliche Termine</h1>
      <div className="viewSection">
        <h2 className="captionFilled">Aktuelle Termine</h2>
        <ul className="tableHead">
          <li className="spaceOne">Datum</li>
          <li className="spaceTwo">Adresse</li>
          <li className="spaceThree">Beschreibung</li>
          <li className="spaceFour">Zugriff</li>
        </ul>
        <ul>
          {api.futureAppointments.map((appointment, index) => (
            <Appointment key={index} data={appointment} />
          ))}
        </ul>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Vergangene Termine</h2>
        <ul className="tableHead">
          <li className="spaceOne">Datum</li>
          <li className="spaceTwo">Adresse</li>
          <li className="spaceThree">Beschreibung</li>
          <li className="spaceFour">Zugriff</li>
        </ul>
        <ul>
          {api.pastAppointments.map((pastAppointment, indexPast) => (
            <Appointment key={indexPast} data={pastAppointment} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(MyAppointments);
