import React from "react";
import { Link } from "react-router-dom";
import "../File.css";

const Appointment = props => {
  let meetingdate = new Date(props.data.date * 1000).toLocaleDateString("de-DE");
  let timedate = new Date(props.data.date * 1000).toLocaleTimeString("de-DE");
  let dateCheck = props.data.date;

  return (
    <li className="inside">
      <div className="spaceOne">
        {dateCheck ? meetingdate : "Kein Termin vereinbart"}
        <br />
        {dateCheck ? timedate + " Uhr" : ""}
      </div>
      <div className="spaceTwo">
        {props.data.city}
        <br />
        {props.data.zipCode}
        <br />
        {props.data.street}
        <br />
        {props.data.houseNumber}
      </div>
      <div className="spaceThree">{props.data.publication}</div>
      <div className="spaceFour">
        <Link
          to={{
            pathname: "/viewappointment",
            state: { id: props.data._id }
          }}
        >
          <button type="button" className="inputRLSubmit small">
            Einsehen
          </button>
        </Link>
      </div>
    </li>
  );
};

export default Appointment;
