import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import "../Checkbox.css";
import useForm from "react-hook-form";
import vars from "../../utils/variables";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateAppointment = props => {
  const id = props.location.state.id;
  const firstname = props.location.state.firstname;
  const lastname = props.location.state.lastname;
  const [publication, setPublic] = useState("");
  const [further, setFurther] = useState("");
  const [meetingdate, setDate] = useState(null);
  const [interpreterRequired, setInterpreterRequired] = useState("");
  const [descriptionMeet, setDescriptionMeet] = useState("");
  const [description, setDescription] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [error, setError] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const handleFetch = () => {
    let date;
    if (meetingdate != null) {
      date = meetingdate.getTime() / 1000;
    } else {
      date = "";
    }

    fetch(`${vars.server}/appointments/appointment/` + id, {
      method: "POST", // or 'PUT'
      body: JSON.stringify({
        publication,
        further,
        date,
        interpreterRequired,
        descriptionMeet,
        description,
        street,
        houseNumber,
        city,
        zipCode
      }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          props.history.push("/medicalrecords", { state: { id: id } });
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangePublic = event => setPublic(event.target.value);
  const handleChangeFurther = event => setFurther(event.target.value);
  const handleChangeInterpreterRequired = event => setInterpreterRequired(event.target.value);
  const handleChangeDescriptionMeet = event => setDescriptionMeet(event.target.value);
  const handleChangeDescription = event => setDescription(event.target.value);
  const handleChangeStreet = event => setStreet(event.target.value);
  const handleChangeHouseNumber = event => setHouseNumber(event.target.value);
  const handleChangeCity = event => setCity(event.target.value);
  const handleChangeZipCode = event => setZipCode(event.target.value);
  const handleChangeAgreement = () => {
    setAgreement(!agreement);
  };

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>
        Neuen Termin erstellen für {firstname} {lastname}
      </h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <h2>Terminvereinbarung</h2>
      <label className="labelRL container">
        <input type="checkbox" name="nameAppointment" onChange={handleChangeAgreement} />
        Falls der/die Medizinstudierende den Termin vereinbaren soll, klicken Sie bitte hier den Haken an. Schreiben Sie
        bitte in das Feld „Anmerkungen”, dass der/die Medizinstudierende den Termin über die Chatfunktion mitteilen
        soll, sobald der Termin feststeht.
        <span className="checkmark"></span>
      </label>{" "}
      <label className={agreement ? "labelRL labelHidden" : "labelRL"}>
        Datum*
        <br />
        <DatePicker
          selected={meetingdate}
          onChange={date => setDate(date)}
          dateFormat="dd.MM.yyyy HH:mm"
          placeholderText="tt.mm.jjjj hh:mm"
          hidden={agreement}
          showTimeSelect
          timeCaption="Zeit"
          timeFormat="HH:mm"
          timeIntervals={15}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="inputRL"
        />
      </label>
      <label className="labelRL">
        Dolmetscher*
        <select
          defaultValue={interpreterRequired}
          onChange={handleChangeInterpreterRequired}
          name="nameInterpreter"
          ref={register({ required: true })}
          className="inputRL"
        >
          <option value="">-</option>
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      </label>
      {errors.nameInterpreter && <p className="error">Pflichtfeld</p>}
      <h2>Anmerkungen</h2>
      <label className="labelRL">
        Öffentliche Anmerkungen*
        <textarea
          type="text"
          defaultValue={publication}
          onChange={handleChangePublic}
          name="namePublic"
          ref={register({ required: true, maxLength: 200 })}
          className="inputRL textareaRL"
          placeholder="z.B. „weibliche Begleitung erwünscht“, „Elternteil begleitet das Kind“, „Schwerbehinderung liegt vor“, „Termin dauert etwas länger“"
        ></textarea>
      </label>
      {errors.namePublic && errors.namePublic.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.namePublic && errors.namePublic.type === "maxLength" && <p className="error">Text ist zu lang</p>}
      <label className="labelRL">
        Weitere Anmerkungen/ Sonstiges
        <textarea
          type="text"
          defaultValue={further}
          onChange={handleChangeFurther}
          name="nameFurther"
          ref={register({ maxLength: 5000 })}
          className="inputRL textareaRL"
          placeholder="Grund des Termins, weitere Infos zum Patienten/zur Patientin. Auf diese Info haben nur der Patientenakte zugewiesene BegleiterInnen Zugriff"
        ></textarea>
      </label>
      {errors.nameFurther && errors.nameFurther.type === "maxLength" && <p className="error">Text ist zu lang</p>}
      <h2>Genauer Treffpunkt</h2>
      <label className="labelRL">
        Genauer Treffpunkt
        <textarea
          type="text"
          defaultValue={descriptionMeet}
          onChange={handleChangeDescriptionMeet}
          name="nameDescriptionMeet"
          ref={register({ maxLength: 2000 })}
          className="inputRL textareaRL"
          placeholder="Der genaue Termintreffpunkt."
        ></textarea>
      </label>
      {errors.nameDescriptionMeet && errors.nameDescriptionMeet.type === "maxLength" && (
        <p className="error">Text ist zu lang</p>
      )}
      <h2>Adresse</h2>
      <label className="labelRL">
        Ortsbeschreibung
        <input
          type="text"
          defaultValue={description}
          onChange={handleChangeDescription}
          name="nameDescription"
          className="inputRL"
          placeholder="Der Termin Abhaltungsort."
        />
      </label>
      <label className="labelRL">
        Straße
        <input
          type="text"
          defaultValue={street}
          onChange={handleChangeStreet}
          name="nameStreet"
          className="inputRL"
          placeholder="Straße"
        />
      </label>
      <label className="labelRL">
        Hausnummer
        <input
          type="text"
          defaultValue={houseNumber}
          onChange={handleChangeHouseNumber}
          name="nameHouseNumber"
          className="inputRL"
          placeholder="Hausnummer"
        />
      </label>
      <label className="labelRL">
        Stadt
        <input
          type="text"
          defaultValue={city}
          onChange={handleChangeCity}
          name="nameCity"
          className="inputRL"
          placeholder="Stadt"
        />
      </label>
      <label className="labelRL">
        Postleitzahl
        <input
          type="text"
          defaultValue={zipCode}
          onChange={handleChangeZipCode}
          name="nameZipCode"
          className="inputRL"
          placeholder="Postleitzahl"
        />
      </label>
      <input type="submit" value="Speichern" className="inputRLSubmit" />
    </form>
  );
};

export default withRouter(CreateAppointment);
