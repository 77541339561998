import React from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";

const JoinedAppointment = props => {
  const id = props.location.state.state.id;
  return (
    <div className="formForm">
      <h2>Sie sind dem Termin beigetreten.</h2>
      <Link to={{ pathname: "/viewappointment", state: { id: id } }}>
        <button type="button" className="inputRLSubmit">
          Termin einsehen
        </button>
      </Link>
    </div>
  );
};

export default withRouter(JoinedAppointment);
