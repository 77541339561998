import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Appointments from "../Appointment/Appointments";
import Chat from "../Chat/Chat";
import "../Form.css";
import vars from "../../utils/variables";
import FileUploadMedicalRecord from "../UploadFile/FileUploadMedicalRecord";

const ViewMedicalRecord = props => {
  const id = props.location.state.id;
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [birthdate, setBirthdate] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [telPrivate, setTelPrivate] = useState("");
  const [telWork, setTelWork] = useState("");
  const [telMobile, setTelMobile] = useState("");
  const [emailPrivate, setEmailPrivate] = useState("");
  const [emailWork, setEmailWork] = useState("");
  const [language, setLanguage] = useState([]);
  const [message, setMessage] = useState("");
  const [participants, setParticipants] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [error, setError] = useState("");
  const [declarationOfAgreement, setDeclarationOfAgreement] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/patientfiles/patientfile/` + id, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setGender(res.patientfile[0].gender);
          setFirstname(res.patientfile[0].firstname);
          setLastname(res.patientfile[0].lastname);
          setLanguage(res.patientfile[0].language);
          setCountry(res.patientfile[0].country);
          setStreet(res.patientfile[0].street);
          setHouseNumber(res.patientfile[0].houseNumber);
          setCity(res.patientfile[0].city);
          setZipCode(res.patientfile[0].zipCode);
          setTelMobile(res.patientfile[0].telMobile);
          setTelWork(res.patientfile[0].telWork);
          setTelPrivate(res.patientfile[0].telPrivate);
          setEmailWork(res.patientfile[0].emailWork);
          setEmailPrivate(res.patientfile[0].emailPrivate);
          setMessage(res.patientfile[0].message);
          setParticipants(res.patientfile[0].joinedStudents);
          setDeclarationOfAgreement(res.patientfile[0].declarationOfAgreement);
          let bdate = new Date(res.patientfile[0].birthdate * 1000).toLocaleDateString("de-DE");
          setBirthdate(bdate);
          if (res.patientfile[0].author) {
            fetchDataAuthorNames(res.patientfile[0].author);
          }
          if (res.patientfile[0].joinedStudents) {
            fetchDataParticipantNames(res.patientfile[0].joinedStudents);
          }
        } else {
          props.history.push("/login");
        }
      });
    }
    async function fetchDataAuthorNames(authorIds) {
      await fetch(`${vars.server}/users/usernames`, {
        method: "POST",
        body: JSON.stringify({
          userIDs: authorIds
        }),
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include"
      })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            setAuthors(result.userNames);
          } else {
            setError(result.message);
          }
        });
    }
    async function fetchDataParticipantNames(participantIds) {
      await fetch(`${vars.server}/users/usernames`, {
        method: "POST",
        body: JSON.stringify({
          userIDs: participantIds
        }),
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include"
      })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            setParticipants(result.userNames);
          } else {
            setError(result.message);
          }
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listAuthors = authors.map((author, index) => <li key={index}>{author}</li>);
  const listParticipants = participants.map((participant, index) => <li key={index}>{participant}</li>);
  const languageValues = language.map((value, key) => <li key={key}>{value.val}</li>);

  return (
    <div className="medicalrecords view">
      <h1>Patientenakte</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <div className="viewSection">
        <h2 className="captionFilled">Person</h2>
        <div className="innerSection">
          <div className="labelSection">Vorname:</div> <strong className="dataSection">{firstname}</strong>
          <br />
          <div className="labelSection">Nachname:</div> <strong className="dataSection">{lastname}</strong>
          <br />
          <div className="labelSection">Gender:</div> <strong className="dataSection">{gender}</strong>
          <br />
          <div className="labelSection">Geburtstag:</div> <strong className="dataSection">{birthdate}</strong>
          <br />
          <br />
          <div className="labelSection">Land:</div> <strong className="dataSection">{country}</strong>
          <br />
          <div className="language">
            <div className="labelSection">Sprachen: </div>
            <strong className="dataSection">
              <ul className="innerList">{languageValues}</ul>
            </strong>
          </div>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Kommunikation</h2>
        <div className="innerSection">
          <div className="labelSection">Mobil Telefon:</div> <strong className="dataSection">{telMobile}</strong>
          <br />
          <div className="labelSection">Privat Telefon:</div> <strong className="dataSection">{telPrivate}</strong>
          <br />
          <div className="labelSection">Arbeit Telefon:</div> <strong className="dataSection">{telWork}</strong>
          <br />
          <br />
          <div className="labelSection">Privat Email:</div> <strong className="dataSection">{emailPrivate}</strong>
          <br />
          <div className="labelSection">Arbeit Email: </div>
          <strong className="dataSection">{emailWork}</strong>
          <br />
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Adresse</h2>
        <div className="innerSection">
          <div className="labelSection">Stadt:</div> <strong className="dataSection">{city}</strong>
          <br />
          <div className="labelSection">Postleitzahl:</div> <strong className="dataSection">{zipCode}</strong>
          <br />
          <div className="labelSection">Straße:</div> <strong className="dataSection">{street}</strong>
          <br />
          <div className="labelSection">Hausnummer:</div> <strong className="dataSection">{houseNumber}</strong>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Vorerkrankungen</h2>
        <div className="innerSection">
          {message}
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Betreuung</h2>
        <div className="innerSection">
          <div className="authors">
            <div className="labelSection">AutorInnen: </div>
            <strong className="dataSection">
              <ul className="innerList">{listAuthors}</ul>
            </strong>
          </div>
          <div className="members">
            <div className="labelSection">BegleiterInnen: </div>
            <strong className="dataSection">
              <ul className="innerList">{listParticipants}</ul>
            </strong>
          </div>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Chat</h2>
        <Chat id={id} />
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Termine</h2>
        <Appointments id={id} />
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Dokumente</h2>
        <div className="innerSection">
          <div className="labelSection">Pflichtdokument:</div>
          <strong className="dataSection">Schweigepflichtsentbindung des Geflüchteten</strong>
          <br />
          <div className="labelSection">Gestattete Dateiformate:</div>
          <strong className="dataSection">.pdf .jpg .jpeg .JPG .JPEG</strong>
          <br />
          <br />
        </div>
        <br />
        <FileUploadMedicalRecord medicalRecordID={id} />
      </div>
      <Link
        to={{
          pathname: "/createappointment",
          state: { id: id, firstname: firstname, lastname: lastname }
        }}
      >
        <button type="button" className="inputRLSubmit">
          Termin erstellen
        </button>
      </Link>
      {props.permissions.createDeletePatientfile && (
        <Link
          to={{
            pathname: "/editmedicalrecord",
            state: { id: id, language: language, country: country, getAllUsers: props.permissions.getAllUsers }
          }}
        >
          <button type="button" className="inputRLSubmit">
            Bearbeiten
          </button>
        </Link>
      )}
      {props.permissions.createDeletePatientfile && (
        <Link
          to={{
            pathname: "/deletemedicalrecord",
            state: { id: id }
          }}
        >
          <button type="button" className="inputRLSubmit delete">
            Löschen
          </button>
        </Link>
      )}
      <Link
        to={{
          pathname: "/leavemedicalrecord",
          state: { id: id }
        }}
      >
        <button type="button" className="inputRLSubmit delete">
          Austreten
        </button>
      </Link>
      {!declarationOfAgreement && props.permissions.acceptRegisteredUserOrPatientfile && (
        <Link
          to={{
            pathname: "/confirmmedicalrecord",
            state: { id: id }
          }}
        >
          <button type="button" className="inputRLSubmit delete">
            Akzeptieren
          </button>
        </Link>
      )}
    </div>
  );
};

export default withRouter(ViewMedicalRecord);
