import React from "react";
import { withRouter } from "react-router-dom";
import "../File.css";
import vars from "../../utils/variables";

const AppointmentsExchange = props => {
  const id = props.data._id;

  const handleFetch = () => {
    fetch(`${vars.server}/exchange/enter/` + id, {
      method: "POST",
      body: JSON.stringify(),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.success) {
          props.history.push("/joinedappointment", { state: { id: id } });
        }
      });
  };

  let meetingdate = new Date(props.data.date * 1000).toLocaleDateString("de-DE");
  let timedate = new Date(props.data.date * 1000).toLocaleTimeString("de-DE");
  let dateCheck = props.data.date;

  return (
    <li className="inside">
      <div className="spaceOne">
        {dateCheck ? meetingdate : "Kein Termin vereinbart"}
        <br />
        {dateCheck ? timedate + " Uhr" : ""}
      </div>
      <div className="spaceTwo">
        {props.data.city}
        <br />
        {props.data.zipCode}
        <br />
        {props.data.street}
        <br />
        {props.data.houseNumber}
      </div>
      <div className="spaceThree">{props.data.publication}</div>
      <div className="spaceFour">
        <button type="button" className="inputRLSubmit small" onClick={handleFetch}>
          Beitreten
        </button>
      </div>
    </li>
  );
};

export default withRouter(AppointmentsExchange);
