import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "whatwg-fetch";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import de from "date-fns/locale/de";
import { setDefaultLocale, registerLocale } from "react-datepicker";

import Register from "../Register/Register";
import Login from "../Login/Login";
import Contact from "../Contact/Contact";
import PostContact from "../Contact/PostContact";
import Dashboard from "../Dashboard/Dashboard";
import CreateMedicalRecord from "../MedicalRecord/CreateMedicalRecord";
import EditMedicalRecord from "../MedicalRecord/EditMedicalRecord";
import CreateAppointment from "../Appointment/CreateAppointment";
import EditAppointment from "../Appointment/EditAppointment";
import EditProfile from "../Profile/EditProfile";
import ViewProfile from "../Profile/ViewProfile";
import DeleteProfile from "../Profile/DeleteProfile";
import Navbar from "../Header/Navbar";
import Footer from "../Footer/Footer";
import Changepassword from "../Changepassword/Changepassword";
import Forgotpassword from "../Forgotpassword/Forgotpassword";
import Resetpassword from "../Forgotpassword/Resetpassword";
import ConfirmEmail from "../ConfirmEmail/ConfirmEmail";
import MedicalRecords from "../MedicalRecord/MedicalRecords";
import ViewMedicalRecord from "../MedicalRecord/ViewMedicalRecord";
import DeleteMedicalRecord from "../MedicalRecord/DeleteMedicalRecord";
import ViewAppointment from "../Appointment/ViewAppointment";
import DeleteAppointment from "../Appointment/DeleteAppointment";
import UserAdministration from "../Administration/AdminUser/UserAdministration";
import AppointmentsAdministration from "../Administration/AdminAppointments/AppointmentsAdministration";
import ViewUser from "../Administration/AdminUser/ViewUser";
import MedicalRecordsAdministration from "../Administration/AdminMedicalRecords/MedicalRecordsAdministration";
import Exchange from "../Exchange/Exchange";
import NotLoggedIn from "../Forwarding/NotLoggedIn";
import NotAuthorized from "../Forwarding/NotAuthorized";
import JoinedAppointment from "../Exchange/JoinedAppointment";
import DeleteUser from "../Administration/AdminUser/DeleteUser";
import EditUser from "../Administration/AdminUser/EditUser";
import LeaveAppointment from "../Appointment/LeaveAppointment";
import LeaveMedicalRecord from "../MedicalRecord/LeaveMedicalRecord";
import MyAppointments from "../Appointment/MyAppointments";
import PostChangePassword from "../Changepassword/PostChangePassword";
import vars from "../../utils/variables";
import AdminRequests from "../Administration/AdminRequests/AdminRequests";
import ConfirmMedicalRecord from "../MedicalRecord/ConfirmMedicalRecord";
import ConfirmUser from "../Administration/AdminUser/ConfirmUser";
import RegisterSuccess from "../Register/RegisterSuccess";
import UserNotAccepted from "../Forwarding/UserNotAccepted";
import NewAdmin from "../Administration/AdminUser/NewAdmin";

const App = () => {
  const [auth, setAuth] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [username, setUsername] = useState("");

  registerLocale("de", de);
  setDefaultLocale("de");

  useEffect(() => {
    fetch(`${vars.server}/users/checkloginstatus`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          setAuth(true);
          fetchPermissions();
        }
      });
  }, []);

  const fetchPermissions = () =>
    fetch(`${vars.server}/users/permissions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          setPermissions(result);
        }
      });

  return (
    <div className="App">
      <Router>
        <Navbar auth={auth} setAuth={setAuth} permissions={permissions} />
        <Switch>
          <Route path="/register">
            <Register auth={auth} />
          </Route>
          <Route path="/login">
            <Login auth={auth} setAuth={setAuth} fetchPermissions={fetchPermissions} />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/postcontact">
            <PostContact />
          </Route>
          <Route path="/dashboard">
            <Dashboard auth={auth} username={username} setUsername={setUsername} />
          </Route>
          <Route path="/createmedicalrecord">
            <CreateMedicalRecord />
          </Route>
          <Route path="/editmedicalrecord">
            <EditMedicalRecord />
          </Route>
          <Route path="/createappointment">
            <CreateAppointment />
          </Route>
          <Route path="/editappointment">
            <EditAppointment />
          </Route>
          <Route path="/editprofile">
            <EditProfile />
          </Route>
          <Route path="/viewprofile">
            <ViewProfile />
          </Route>
          <Route path="/deleteprofile">
            <DeleteProfile />
          </Route>
          <Route path="/changepassword">
            <Changepassword />
          </Route>
          <Route path="/forgotpassword">
            <Forgotpassword />
          </Route>
          <Route path="/resetpassword">
            <Resetpassword />
          </Route>
          <Route path="/medicalrecords">
            <MedicalRecords permissions={permissions} />
          </Route>
          <Route path="/viewmedicalrecord">
            <ViewMedicalRecord permissions={permissions} />
          </Route>
          <Route path="/confirmemail">
            <ConfirmEmail />
          </Route>
          <Route path="/deletemedicalrecord">
            <DeleteMedicalRecord />
          </Route>
          <Route path="/viewappointment">
            <ViewAppointment permissions={permissions} />
          </Route>
          <Route path="/deleteappointment">
            <DeleteAppointment />
          </Route>
          <Route path="/useradministration">
            <UserAdministration />
          </Route>
          <Route path="/medicalrecordsadministration">
            <MedicalRecordsAdministration />
          </Route>
          <Route path="/appointmentsadministration">
            <AppointmentsAdministration />
          </Route>
          <Route path="/viewuser">
            <ViewUser />
          </Route>
          <Route path="/exchange">
            <Exchange />
          </Route>
          <Route path="/notloggedin">
            <NotLoggedIn />
          </Route>
          <Route path="/notauthorized">
            <NotAuthorized />
          </Route>
          <Route path="/joinedappointment">
            <JoinedAppointment />
          </Route>
          <Route path="/deleteuser">
            <DeleteUser />
          </Route>
          <Route path="/edituser">
            <EditUser />
          </Route>
          <Route path="/leaveappointment">
            <LeaveAppointment />
          </Route>
          <Route path="/leavemedicalrecord">
            <LeaveMedicalRecord />
          </Route>
          <Route path="/myappointments">
            <MyAppointments />
          </Route>
          <Route path="/postchangepassword">
            <PostChangePassword />
          </Route>
          <Route path="/adminrequests">
            <AdminRequests />
          </Route>
          <Route path="/confirmmedicalrecord">
            <ConfirmMedicalRecord />
          </Route>
          <Route path="/confirmuser">
            <ConfirmUser />
          </Route>
          <Route path="/registersuccess">
            <RegisterSuccess />
          </Route>
          <Route path="/usernotaccepted">
            <UserNotAccepted auth={auth} />
          </Route>
          <Route path="/newadmin">
            <NewAdmin />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
