import React from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";

const PostContact = () => {
  return (
    <div className="formForm">
      <h1>Kontaktanfrage versandt</h1>
      <p>Ihre Kontaktanfrage wurde erfolgreich versandt.</p>
    </div>
  );
};

export default withRouter(PostContact);
