import React from "react";
import { Link } from "react-router-dom";

const MedicalRecord = props => {
  const languageValues = props.data.language.map((value, key) => <li key={key}>{value.val}</li>);

  return (
    <li className="inside">
      <div className="spaceOne">
        {props.data.firstname}
        <br />
        {props.data.lastname}
        <br />
        {props.data.gender}
      </div>
      <div className="spaceTwo">
        <ul className="innerList">{languageValues}</ul>
      </div>
      <div className="spaceThree">
        {props.data.telMobile} <br />
        {props.data.telPrivate} <br />
        {props.data.telWork} <br />
        {props.data.emailPrivate} <br />
        {props.data.emailWork}
      </div>
      <div className="spaceFour">
        <Link
          to={{
            pathname: "/viewmedicalrecord",
            state: {
              id: props.data._id,
              listParticipants: props.data.listParticipants
            }
          }}
        >
          <button type="button" className="inputRLSubmit small">
            Einsehen
          </button>
        </Link>
      </div>
    </li>
  );
};

export default MedicalRecord;
