import React, { useState, useEffect } from "react";
import "../Administration.css";
import "../../Form.css";
import "../../File.css";
import { withRouter } from "react-router-dom";
import MedicalRecord from "../../MedicalRecord/MedicalRecord";
import NavbarAdministration from "../NavbarAdministration";
import vars from "../../../utils/variables";

const MedicalRecordsAdministration = props => {
  const [api, setApi] = useState({ patientfiles: [] });

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/administration/allpatientfiles`, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setApi(res);
        } else if (res.subject === "NotAuthenticated") {
          props.history.push("/login");
        } else if (res.subject === "NotAuthorized") {
          props.history.push("/notauthorized");
        }
      });
    }
    fetchData();
  }, [props]);

  return (
    <div className="Administration">
      <NavbarAdministration />
      <h1>Alle Patientenakten</h1>
      <div className="Admin">
        <ul className="tableHead">
          <li className="spaceOne">Name</li>
          <li className="spaceTwo">Sprache</li>
          <li className="spaceThree">Kommunikation</li>
          <li className="spaceFour">Zugriff</li>
        </ul>
        <ul>
          {api.patientfiles.map((patientfile, index) => (
            <MedicalRecord key={index} data={patientfile} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(MedicalRecordsAdministration);
