import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";
import vars from "../../utils/variables";

const ViewAppointment = props => {
  const id = props.location.state.id;
  const [publication, setPublic] = useState("");
  const [further, setFurther] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [interpreterRequired, setInterpreterRequired] = useState(false);
  const [descriptionMeet, setDescriptionMeet] = useState("");
  const [description, setDescription] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [studentParticipant, setStudentParticipant] = useState("");
  const [interpreterParticipant, setInterpreterParticipant] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [dateCheck, setdateCheck] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/appointments/appointment/` + id, {
        credentials: "include"
      });
      res.json().then(res => {
        setPublic(res.appointment.publication);
        setFurther(res.appointment.further);
        setDate(res.appointment.date);
        setInterpreterRequired(res.appointment.interpreterRequired);
        setDescription(res.appointment.description);
        setDescriptionMeet(res.appointment.descriptionMeet);
        setStreet(res.appointment.street);
        setHouseNumber(res.appointment.houseNumber);
        setCity(res.appointment.city);
        setZipCode(res.appointment.zipCode);
        setStudentParticipant(res.appointment.medicalStudent);
        setInterpreterParticipant(res.appointment.interpreter);
        let meetingdate = new Date(res.appointment.date * 1000).toLocaleDateString("de-DE");
        setDate(meetingdate);
        let timedate = new Date(res.appointment.date * 1000).toLocaleTimeString("de-DE");
        setTime(timedate);
        setdateCheck(res.appointment.date);
        if (res.appointment.author[0]) {
          fetchDataAuthorName(res.appointment.author[0]);
        }
        if (res.appointment.medicalStudent[0]) {
          fetchDataMedicineStudentName(res.appointment.medicalStudent[0]);
        }
        if (res.appointment.interpreter[0]) {
          fetchDataInterpreterName(res.appointment.interpreter[0]);
        }
      });
    }
    async function fetchDataAuthorName(id) {
      const res = await fetch(`${vars.server}/users/username/` + id, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setAuthorName(res.username);
        }
      });
    }
    async function fetchDataMedicineStudentName(id) {
      const res = await fetch(`${vars.server}/users/username/` + id, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setStudentParticipant(res.username);
        }
      });
    }
    async function fetchDataInterpreterName(id) {
      const res = await fetch(`${vars.server}/users/username/` + id, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setInterpreterParticipant(res.username);
        }
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="medicalrecords view">
      <h1>Termin</h1>
      <div className="viewSection">
        <h2 className="captionFilled">Datum</h2>
        <div className="innerSection">
          <div className="labelSection">Tag:</div>{" "}
          <strong className="dataSection"> {dateCheck ? date : "Kein Termin vereinbart"}</strong>
          <br />
          <div className="labelSection">Uhrzeit:</div>{" "}
          <strong className="dataSection"> {dateCheck ? time + " Uhr" : ""}</strong>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Beschreibung</h2>
        <div className="innerSection">
          <div className="labelSection">Dolmetscher benötigt:</div>{" "}
          <strong className="dataSection">{interpreterRequired ? "Ja" : "Nein"}</strong>
          <br />
          <br />
          {publication}
          <br />
          <br />
          {further}
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Adresse</h2>
        <div className="innerSection">
          <div className="labelSection">Stadt:</div> <strong className="dataSection">{city}</strong>
          <br />
          <div className="labelSection">Postleitzahl:</div> <strong className="dataSection">{zipCode}</strong>
          <br />
          <div className="labelSection">Straße:</div> <strong className="dataSection">{street}</strong>
          <br />
          <div className="labelSection">Hausnummer:</div> <strong className="dataSection">{houseNumber}</strong>
          <br />
          <br />
          {description} <br />
          <br /> {descriptionMeet} <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Betreuung</h2>
        <div className="innerSection">
          <div className="labelSection">AutorIn:</div> <strong className="dataSection"> {authorName}</strong>
          <br />
          <br />
          <div className="labelSection">MedizinstudentIn:</div>{" "}
          <strong className="dataSection"> {studentParticipant}</strong>
          <br />
          <div className="labelSection">DolmetscherIn:</div>{" "}
          <strong className="dataSection"> {interpreterParticipant}</strong>
          <br />
        </div>
      </div>
      <Link to={{ pathname: "/editappointment", state: { id: id, interpreterRequired: interpreterRequired } }}>
        <button type="button" className="inputRLSubmit">
          Bearbeiten
        </button>
      </Link>
      {props.permissions.createDeleteAppointment && (
        <Link
          to={{
            pathname: "/deleteappointment",
            state: { id: id }
          }}
        >
          <button type="button" className="inputRLSubmit delete">
            Löschen
          </button>
        </Link>
      )}
      <Link
        to={{
          pathname: "/leaveappointment",
          state: { id: id }
        }}
      >
        <button type="button" className="inputRLSubmit delete">
          Austreten
        </button>
      </Link>
    </div>
  );
};

export default withRouter(ViewAppointment);
