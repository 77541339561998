import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import "../Checkbox.css";
import useForm from "react-hook-form";
import vars from "../../utils/variables";
import { namePattern, emailPattern } from "../Pattern/Pattern";

const Contact = ({ history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const handleFetch = () => {
    fetch(`${vars.server}/contact`, {
      method: "POST",
      body: JSON.stringify({ name, email, message }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          history.push("/postcontact");
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangeName = event => setName(event.target.value);
  const handleChangeEmail = event => setEmail(event.target.value);
  const handleChangeMessage = event => setMessage(event.target.value);

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Kontakt</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <label className="labelRL">
        Name*
        <input
          type="text"
          defaultValue={name}
          onChange={handleChangeName}
          name="nameName"
          ref={register({
            required: true,
            pattern: namePattern
          })}
          className="inputRL"
        />
      </label>
      {errors.nameName && errors.nameName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameName && errors.nameName.type === "pattern" && <p className="error">Invalider Name</p>}
      <label className="labelRL">
        Email*
        <input
          type="text"
          defaultValue={email}
          onChange={handleChangeEmail}
          name="emailName"
          ref={register({
            required: true,
            pattern: emailPattern
          })}
          className="inputRL"
        />
      </label>
      {errors.emailName && errors.emailName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.emailName && errors.emailName.type === "pattern" && <p className="error">Invalide Mail Adresse</p>}
      <label className="labelRL">
        Nachricht*
        <textarea
          type="text"
          defaultValue={message}
          onChange={handleChangeMessage}
          name="messageName"
          ref={register({ required: true, maxLength: 5000 })}
          className="inputRL textareaRL textareaContact"
        ></textarea>
      </label>
      {errors.messageName && errors.messageName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.messageName && errors.messageName.type === "maxLength" && <p className="error">Text ist zu lang</p>}
      <label className="labelRL container">
        Hiermit akzeptiere ich die <strong>Datenschutzbestimmungen</strong>* <br />
        <input
          type="checkbox"
          name="nameDSB"
          ref={register({
            required: true
          })}
        />
        <span className="checkmark"></span>
      </label>
      {errors.nameDSB && <p className="error">Bitte akzeptieren Sie die Datenschutzbestimmungen</p>}
      <input type="submit" value="Nachricht senden" className="inputRLSubmit" />
    </form>
  );
};

export default withRouter(Contact);
