import React from "react";
import LanguageOptions from "./LanguageOptions";

const LanguageComponent = props => {
  return (
    <div>
      <input type="button" value="Sprachen erweitern" onClick={props.addLanguage} className="inputRLSubmit small" />
      {props.languageState.map((v, idx) => {
        const languageId = `val-${idx}`;
        return (
          <div key={`cat-${idx}`}>
            <label htmlFor={languageId}>
              {`Neue Sprache #${idx + 1}`}
              <LanguageOptions
                val={languageId}
                dataIdx={idx}
                id={languageId}
                className="val"
                defaultValue={props.languageState[idx]}
                onChange={props.handleLanguageState}
              />
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default LanguageComponent;
