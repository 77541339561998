import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "../../Form.css";
import vars from "../../../utils/variables";
import FileUploadUser from "../../UploadFile/FileUploadUser";

const ViewUser = props => {
  if (!props.location.state) {
    props.history.push("/dashboard");
  }
  const id = props.location.state ? props.location.state.id : undefined;
  const [job, setJob] = useState("");
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [firm, setFirm] = useState("");
  const [language, setLanguage] = useState([]);
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [tel, setTel] = useState("");
  const [telWork, setTelWork] = useState("");
  const [telPrivate, setTelPrivate] = useState("");
  const [emailWork, setEmailWork] = useState("");
  const [email, setEmail] = useState("");
  const [birthdate, setBirthdate] = useState(null);
  const [getNotifications, setGetNotifications] = useState("");
  const [filesChecked, setFilesChecked] = useState(false);
  const [statistic, setStatistic] = useState("");

  //Appointment Permissions
  const [createDeleteAppointment, setCreateDeleteAppointment] = useState("");
  const [getAndEnterAllOpenAppointments, setGetAndEnterAllOpenAppointments] = useState("");
  const [getAllAppointments, setGetAllAppointments] = useState("");
  const [updateDeleteEveryAppointment, setUpdateDeleteEveryAppointment] = useState("");
  //MedicalRecord Permissions
  const [createDeletePatientfile, setCreateDeletePatientfile] = useState("");
  const [updateDeleteEveryPatientfile, setUpdateDeleteEveryPatientfile] = useState("");
  const [getAllPatientfiles, setGetAllPatientfiles] = useState("");
  //User Permissions
  const [updateDeleteEveryUserProfile, setUpdateDeleteEveryUserProfile] = useState("");
  const [getAllUsers, setGetAllUsers] = useState("");
  const [declareNewAdmin, setDeclareAdmin] = useState("");
  const [acceptRegisteredUserOrPatientfile, setAcceptRegisteredUserOrPatientfile] = useState("");
  const [removeAddUserInAppointmentOrPatientfile, setRemoveAddUserInAppointmentOrPatientfile] = useState("");

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/administration/userid/` + id, {
        credentials: "include"
      });
      res.json().then(res => {
        setJob(res.user.job);
        setFirstname(res.user.firstname);
        setLastname(res.user.lastname);
        setFirm(res.user.firm);
        setGender(res.user.gender);
        setLanguage(res.user.language);
        setStreet(res.user.street);
        setHouseNumber(res.user.houseNumber);
        setCity(res.user.city);
        setZipCode(res.user.zipCode);
        setTel(res.user.tel);
        setTelWork(res.user.telWork);
        setTelPrivate(res.user.telPrivate);
        setEmailWork(res.user.emailWork);
        setEmail(res.user.email);
        setGetNotifications(res.user.getNotifications);
        setFilesChecked(res.user.filesChecked);
        let bdate = new Date(res.user.birthdate * 1000).toLocaleDateString("de-DE");
        setBirthdate(bdate);

        //Appointment Permissions
        setCreateDeleteAppointment(res.user.permissions.createDeleteAppointment);
        setGetAndEnterAllOpenAppointments(res.user.permissions.getAndEnterAllOpenAppointments);
        setUpdateDeleteEveryAppointment(res.user.permissions.updateDeleteEveryAppointment);
        setGetAllAppointments(res.user.permissions.getAllAppointments);
        //MedicalRecordPermissions
        setCreateDeletePatientfile(res.user.permissions.createDeletePatientfile);
        setUpdateDeleteEveryPatientfile(res.user.permissions.updateDeleteEveryPatientfile);
        setGetAllPatientfiles(res.user.permissions.getAllPatientfiles);
        //UserPermissions
        setUpdateDeleteEveryUserProfile(res.user.permissions.updateDeleteEveryUserProfile);
        setGetAllUsers(res.user.permissions.getAllUsers);
        setDeclareAdmin(res.user.permissions.declareNewAdmin);
        setAcceptRegisteredUserOrPatientfile(res.user.permissions.acceptRegisteredUserOrPatientfile);
        setRemoveAddUserInAppointmentOrPatientfile(res.user.permissions.removeAddUserInAppointmentOrPatientfile);
      });
    }
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/administration//userStatisticsAppointments/` + id, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setStatistic(res);
        }
      });
    }
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const languageValues = language.map((value, key) => <li key={key}>{value.val}</li>);

  return (
    <div className="medicalrecords view">
      <h1>Nutzer</h1>
      <div className="viewSection">
        <h2 className="captionFilled">Person</h2>
        <div className="innerSection">
          <div className="labelSection">Vorname:</div> <strong className="dataSection">{firstname}</strong>
          <br />
          <div className="labelSection">Nachname:</div> <strong className="dataSection">{lastname}</strong>
          <br />
          <div className="labelSection">Gender:</div> <strong className="dataSection">{gender}</strong>
          <br />
          <div className="labelSection">Geburtstag:</div> <strong className="dataSection">{birthdate}</strong>
          <br />
          <br />
          <div className="labelSection">Beschäftigung:</div> <strong className="dataSection">{job}</strong>
          <br />
          <div className="labelSection">Firma/Institution: </div>
          <strong className="dataSection">{firm}</strong>
          <br />
          <div className="language">
            <div className="labelSection">Sprachen: </div>
            <strong className="dataSection">
              <ul className="innerList">{languageValues}</ul>
            </strong>
          </div>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Kommunikation</h2>
        <div className="innerSection">
          <div className="labelSection">Mobil Telefon:</div> <strong className="dataSection">{tel}</strong>
          <br />
          <div className="labelSection">Privat Telefon:</div> <strong className="dataSection">{telPrivate}</strong>
          <br />
          <div className="labelSection">Arbeit Telefon:</div> <strong className="dataSection">{telWork}</strong>
          <br />
          <br />
          <div className="labelSection">Privat Email:</div> <strong className="dataSection">{email}</strong>
          <br />
          <div className="labelSection">Arbeit Email: </div>
          <strong className="dataSection">{emailWork}</strong>
          <br />
          <br />
          <div className="labelSection">Benachrichtigung:</div>{" "}
          <strong className="dataSection">{getNotifications ? "Erhalten" : "Nicht erhalten"}</strong>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Adresse</h2>
        <div className="innerSection">
          <div className="labelSection">Stadt:</div> <strong className="dataSection">{city}</strong>
          <br />
          <div className="labelSection">Postleitzahl:</div> <strong className="dataSection">{zipCode}</strong>
          <br />
          <div className="labelSection">Straße:</div> <strong className="dataSection">{street}</strong>
          <br />
          <div className="labelSection">Hausnummer:</div> <strong className="dataSection">{houseNumber}</strong>
          <br />
        </div>
      </div>
      {job !== "SozialarbeiterIn" && (
        <div className="viewSection">
          <h2 className="captionFilled"> {job !== "SozialarbeiterIn" ? "Statistik" : ""}</h2>
          <div className="innerSection">
            {job !== "SozialarbeiterIn"
              ? "Der Nutzer hat in den letzten 6 Monaten an " +
                statistic.numberOfAppointments +
                " Terminen teilgenommen"
              : ""}
            <br />
          </div>
        </div>
      )}
      <div className="viewSection">
        <h2 className="captionFilled">Rechte</h2>
        <div className="innerSection">
          <ul className="innerList">
            {/* appointments */}
            <li>{createDeleteAppointment ? "Termine erstellen und löschen" : ""}</li>
            <li>{getAndEnterAllOpenAppointments ? "Offene Termine einsehen und ihnen beitreten" : ""}</li>
            <li>{updateDeleteEveryAppointment ? "Alle Termine bearbeiten und löschen (Administration)" : ""}</li>
            <li>{getAllAppointments ? "Alle Termine einsehen (Administration)" : ""}</li>
            <br />
            {/* medicalrecords */}
            <li>{createDeletePatientfile ? "Patientenakte erstellen, bearbeiten und löschen" : ""}</li>
            <li>{updateDeleteEveryPatientfile ? "Alle Patientenakten bearbeiten und löschen (Administration)" : ""}</li>
            <li>{getAllPatientfiles ? "Alle Patientenakten einsehen (Administration)" : ""}</li>
            <br />
            {/* users */}
            <li>{updateDeleteEveryUserProfile ? "Alle Nutzer bearbeiten und löschen (Administration)" : ""}</li>
            <li>{getAllUsers ? "Alle Nutzer einsehen (Administration)" : ""}</li>
            <li>{declareNewAdmin ? "Neuen Administrator festlegen (Administration)" : ""}</li>
            <br />
            <li>
              {acceptRegisteredUserOrPatientfile
                ? "Registrierte(n) Nutzer/ Patientenakte akzeptieren (Administration)"
                : ""}
            </li>
            <li>
              {removeAddUserInAppointmentOrPatientfile
                ? "Nutzer aus Termin oder Patientenakte entfernen, sowie hinzufügen (Administration)"
                : ""}
            </li>
          </ul>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Dokumente</h2>
        <FileUploadUser userID={id} showOnlyDocumentsNotUpload={true} />
      </div>

      <Link
        to={{
          pathname: "/edituser",
          state: {
            id: id,
            language: language,
            getNotifications: getNotifications,
            createDeleteAppointment: createDeleteAppointment,
            getAndEnterAllOpenAppointments: getAndEnterAllOpenAppointments,
            updateDeleteEveryAppointment: updateDeleteEveryAppointment,
            getAllAppointments: getAllAppointments,
            createDeletePatientfile: createDeletePatientfile,
            updateDeleteEveryPatientfile: updateDeleteEveryPatientfile,
            getAllPatientfiles: getAllPatientfiles,
            updateDeleteEveryUserProfile: updateDeleteEveryUserProfile,
            getAllUsers: getAllUsers,
            declareNewAdmin: declareNewAdmin,
            acceptRegisteredUserOrPatientfile: acceptRegisteredUserOrPatientfile,
            removeAddUserInAppointmentOrPatientfile: removeAddUserInAppointmentOrPatientfile
          }
        }}
      >
        <button type="button" className="inputRLSubmit">
          Bearbeiten
        </button>
      </Link>
      <Link
        to={{
          pathname: "/deleteuser",
          state: { id: id }
        }}
      >
        <button type="button" className="inputRLSubmit delete">
          Löschen
        </button>
      </Link>
      {!filesChecked && (
        <Link
          to={{
            pathname: "/confirmuser",
            state: { id: id }
          }}
        >
          <button type="button" className="inputRLSubmit delete">
            Akzeptieren
          </button>
        </Link>
      )}
    </div>
  );
};

export default withRouter(ViewUser);
