import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import useForm from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CountryOptions from "../FormComponents/CountryOptions";
import LanguageComponent from "../FormComponents/LanguageComponent";
import { namePattern, telPattern, emailPattern } from "../Pattern/Pattern";
import vars from "../../utils/variables";

const EditMedicalRecord = props => {
  const id = props.location.state.id;
  const [country, setCountry] = useState(props.location.state.country);
  const [bdate, setBirthdate] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [telPrivate, setTelPrivate] = useState("");
  const [telWork, setTelWork] = useState("");
  const [telMobile, setTelMobile] = useState("");
  const [emailPrivate, setEmailPrivate] = useState("");
  const [emailWork, setEmailWork] = useState("");
  const [oldLanguage, setOldLanguage] = useState(props.location.state.language);
  const [message, setMessage] = useState("");
  const [addAuthor, setAddAuthor] = useState();
  const [author, setAuthor] = useState([]);
  const [bdateOld, setBdateOld] = useState("");
  const [api, setApi] = useState({ users: [] });
  const [error, setError] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/administration/allSocialworkersOrAdmins`, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setApi(res);
        } else if (res.subject === "NotAuthenticated") {
          props.history.push("/login");
        } else if (res.subject === "NotAuthorized") {
          props.history.push("/notauthorized");
        }
      });
    }
    if (props.location.state.getAllUsers) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/patientfiles/patientfile/` + id, {
        credentials: "include"
      });
      res.json().then(res => {
        setFirstname(res.patientfile[0].firstname);
        setLastname(res.patientfile[0].lastname);
        setOldLanguage(res.patientfile[0].language);
        setStreet(res.patientfile[0].street);
        setHouseNumber(res.patientfile[0].houseNumber);
        setCity(res.patientfile[0].city);
        setZipCode(res.patientfile[0].zipCode);
        setTelMobile(res.patientfile[0].telMobile);
        setTelWork(res.patientfile[0].telWork);
        setTelPrivate(res.patientfile[0].telPrivate);
        setEmailWork(res.patientfile[0].emailWork);
        setEmailPrivate(res.patientfile[0].emailPrivate);
        setBirthdate(res.patientfile[0].birthdate * 1000);
        setMessage(res.patientfile[0].message);
        setBdateOld(res.patientfile[0].birthdate * 1000);
        setAuthor(res.patientfile[0].author);
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetch = () => {
    let birthdate;
    if (bdate === bdateOld) {
      birthdate = bdate / 1000;
    } else if (bdate != null) {
      birthdate = bdate.getTime() / 1000;
    } else {
      birthdate = "";
    }
    if (addAuthor) {
      setAuthor(author.push(addAuthor));
    }
    for (var i = languageState.length - 1; i >= 0; i--) {
      if (!languageState[i].val) {
        languageState.splice(i, 1);
      }
    }
    let language = oldLanguage.concat(languageState);

    fetch(`${vars.server}/patientfiles/edit/` + id, {
      method: "POST",
      body: JSON.stringify({
        author,
        country,
        birthdate,
        firstname,
        lastname,
        street,
        houseNumber,
        city,
        zipCode,
        telPrivate,
        telWork,
        telMobile,
        emailPrivate,
        emailWork,
        language,
        message
      }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          props.history.push("/medicalrecords");
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangeCountry = event => setCountry(event.target.value);
  const handleChangeFirstName = event => setFirstname(event.target.value);
  const handleChangeLastName = event => setLastname(event.target.value);
  const handleChangeStreet = event => setStreet(event.target.value);
  const handleChangeHouseNumber = event => setHouseNumber(event.target.value);
  const handleChangeCity = event => setCity(event.target.value);
  const handleChangeZipCode = event => setZipCode(event.target.value);
  const handleChangeTelPrivate = event => setTelPrivate(event.target.value);
  const handleChangeTelWork = event => setTelWork(event.target.value);
  const handleChangeTelMobile = event => setTelMobile(event.target.value);
  const handleChangeEmailPrivate = event => setEmailPrivate(event.target.value);
  const handleChangeEmailWork = event => setEmailWork(event.target.value);
  const handleChangeMessage = event => setMessage(event.target.value);
  const handleChangeAuthor = event => setAddAuthor(event.target.value);

  //Language
  const blankLanguage = { val: "" };
  const [languageState, setLanguageState] = useState([{ ...blankLanguage }]);
  const addLanguage = () => {
    setLanguageState([...languageState, { ...blankLanguage }]);
  };
  const handleLanguageState = e => {
    const updatedLangugaes = [...languageState];
    updatedLangugaes[e.target.dataset.idx][e.target.className] = e.target.value;
    setLanguageState(updatedLangugaes);
  };

  const oldLanguageValues = oldLanguage.map((value, key) => <li key={key}>{value.val}</li>);
  const listAllUsers = api.users.map((user, index) => (
    <option key={index} value={user._id}>
      {user.firstname} {user.lastname}
    </option>
  ));

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Patientenakte bearbeiten</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <h2>Kontaktangaben zum Geflüchteten</h2>
      <p>
        Die Kontaktdaten werden möglichen BegleiterInnen, die noch nicht dem Fall beigetreten sind, anonymisiert
        dargestellt.
      </p>
      <label className="labelRL">
        Vorname*
        <input
          type="text"
          defaultValue={firstname}
          onChange={handleChangeFirstName}
          name="nameFirstName"
          ref={register({
            required: true,
            pattern: namePattern
          })}
          className="inputRL"
          placeholder="Vorname"
        />
      </label>
      {errors.nameFirstName && errors.nameFirstName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameFirstName && errors.nameFirstName.type === "pattern" && <p className="error">Invalider Vorname</p>}
      <label className="labelRL">
        Nachname*
        <input
          type="text"
          defaultValue={lastname}
          onChange={handleChangeLastName}
          name="nameLastName"
          ref={register({
            required: true,
            pattern: namePattern
          })}
          className="inputRL"
          placeholder="Nachname"
        />
      </label>
      {errors.nameLastName && errors.nameLastName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameLastName && errors.nameLastName.type === "pattern" && <p className="error">Invalider Nachname</p>}
      <label className="labelRL">
        Land
        <CountryOptions country={country} handleChangeCountry={handleChangeCountry} />
      </label>
      <label className="labelRL">
        Geburtsdatum*
        <br />
        <DatePicker
          selected={bdate}
          onChange={date => setBirthdate(date)}
          dateFormat="dd.MM.yyyy"
          placeholderText="tt.mm.jjjj"
          required
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="inputRL"
        />
      </label>
      <h2>Adresse des Geflüchteten</h2>
      <p>Die Adresse wird nur beigetretenen BegleiterInnen angezeigt.</p>
      <label className="labelRL">
        Straße
        <input
          type="text"
          defaultValue={street}
          onChange={handleChangeStreet}
          name="nameStreet"
          className="inputRL"
          placeholder="Straße"
        />
      </label>
      <label className="labelRL">
        Hausnummer
        <input
          type="text"
          defaultValue={houseNumber}
          onChange={handleChangeHouseNumber}
          name="nameHouseNumber"
          className="inputRL"
          placeholder="Hausnummer"
        />
      </label>
      <label className="labelRL">
        Stadt
        <input
          type="text"
          defaultValue={city}
          onChange={handleChangeCity}
          name="nameCity"
          className="inputRL"
          placeholder="Stadt"
        />
      </label>
      <label className="labelRL">
        Postleitzahl
        <input
          type="text"
          defaultValue={zipCode}
          onChange={handleChangeZipCode}
          name="nameZipCode"
          className="inputRL"
          placeholder="Postleitzahl"
        />
      </label>
      <h2>Kommunikation mit dem Geflüchteten</h2>
      <p>Bitte geben Sie mindestens eine Kommunikationsmöglichkeit an.</p>
      <label className="labelRL">
        Telefon Mobil*
        <input
          type="tel"
          defaultValue={telMobile}
          onChange={handleChangeTelMobile}
          name="nameTelMobile"
          ref={register({
            required: true,
            pattern: telPattern
          })}
          className="inputRL"
          placeholder="Telefonnummer"
        />
      </label>
      {errors.nameTelMobile && errors.nameTelMobile.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameTelMobile && errors.nameTelMobile.type === "pattern" && (
        <p className="error">Invalide Telefonnummer</p>
      )}
      <label className="labelRL">
        Telefon Privat
        <input
          type="tel"
          defaultValue={telPrivate}
          onChange={handleChangeTelPrivate}
          name="nameTelPrivate"
          ref={register({
            pattern: telPattern
          })}
          className="inputRL"
          placeholder="Telefonnummer"
        />
      </label>
      {errors.nameTelPrivate && errors.nameTelPrivate.type === "pattern" && (
        <p className="error">Invalide Telefonnummer</p>
      )}
      <label className="labelRL">
        Telefon Arbeit
        <input
          type="tel"
          defaultValue={telWork}
          onChange={handleChangeTelWork}
          name="nameTel"
          ref={register({
            pattern: telPattern
          })}
          className="inputRL"
          placeholder="Telefonnummer"
        />
      </label>
      {errors.nameTelWork && errors.nameTelWork.type === "pattern" && <p className="error">Invalide Telefonnummer</p>}
      <label className="labelRL">
        Email Privat
        <input
          type="text"
          defaultValue={emailPrivate}
          onChange={handleChangeEmailPrivate}
          name="emailNamePrivate"
          ref={register({
            pattern: emailPattern
          })}
          className="inputRL"
          placeholder="max.mustermann@mail.de"
        />
      </label>
      {errors.emailNamePrivate && errors.emailNamePrivate.type === "pattern" && (
        <p className="error">Invalide Mail Adresse</p>
      )}
      <label className="labelRL">
        Email Arbeit
        <input
          type="text"
          defaultValue={emailWork}
          onChange={handleChangeEmailWork}
          name="emailNameWork"
          ref={register({
            pattern: emailPattern
          })}
          className="inputRL"
          placeholder="max.mustermann@mail.de"
        />
      </label>
      {errors.emailNameWork && errors.emailNameWork.type === "pattern" && (
        <p className="error">Invalide Mail Adresse</p>
      )}
      <h2>Sprachen des Geflüchteten</h2>
      <label className="labelRL">
        Bisherige Sprachen
        <ul className="boxList"> {oldLanguageValues}</ul>
        <br />
        <LanguageComponent
          addLanguage={addLanguage}
          languageState={languageState}
          handleLanguageState={handleLanguageState}
        />
      </label>
      <h2>Anamnese und Vorerkrankungen</h2>
      <p>
        Bitte trage hier die Anamnese und Vorerkrankungen der Person ein. Auf diese Info haben alle der Patientenakte
        zugewiesenen BegleiterInnen Zugriff.
      </p>
      <label className="labelRL">
        <textarea
          type="text"
          defaultValue={message}
          onChange={handleChangeMessage}
          name="messageName"
          ref={register({ maxLength: 8000 })}
          className="inputRL textareaRL"
          placeholder="Anamnese und Vorerkrankungen"
        ></textarea>
      </label>
      {errors.messageName && errors.messageName.type === "maxLength" && <p className="error">Text ist zu lang</p>}
      <h2>AutorIn hinzufügen</h2>
      <select defaultValue={addAuthor} onChange={handleChangeAuthor} name="nameAuthor" className="inputRL">
        <option value="">-</option>
        {listAllUsers}
      </select>
      <input type="submit" value="Speichern" className="inputRLSubmit" />
    </form>
  );
};

export default withRouter(EditMedicalRecord);
