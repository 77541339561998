import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ChatMessage from "../Chat/ChatMessage";
import useForm from "react-hook-form";
import "../Form.css";
import "../File.css";
import "./Chat.css";
import vars from "../../utils/variables";

const Chat = props => {
  const [api, setApi] = useState({ chatMessages: [] });
  const id = props.id;
  const [error, setError] = useState(false);
  const [chatMessage, setChatMessage] = useState("");

  const { handleSubmit } = useForm();

  async function fetchData() {
    const res = await fetch(`${vars.server}/patientfiles/chat/` + id, {
      credentials: "include"
    });
    res.json().then(res => {
      setApi(res);
    });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchChat = setInterval(() => fetchData(), 1000);
    return () => clearInterval(fetchChat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetch = () => {
    fetch(`${vars.server}/patientfiles/chat/` + id, {
      method: "POST", // or 'PUT'
      body: JSON.stringify({ chatMessage }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          fetchData();
          setChatMessage("");
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangeChatMessage = event => setChatMessage(event.target.value);

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formChat">
      <div className="chat">
        {error && <p className="errorMessage">Fehler: {error}</p>}
        <ul className="scrollChat">
          {api.chatMessages.map((chatmessage, index) => (
            <ChatMessage key={index} data={chatmessage} />
          ))}
        </ul>
        <div className="textareaChat">
          <textarea
            type="text"
            onChange={handleChangeChatMessage}
            value={chatMessage}
            name="nameLastName"
            className="inputRL textareaRL"
            placeholder="Nachricht hier eingeben"
          />
        </div>
        <input type="submit" value="Senden" className="inputRLSubmit" />
      </div>
    </form>
  );
};

export default withRouter(Chat);
