import React from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";

const RegisterSuccess = () => {
  return (
    <div className="formForm">
      <h1>Registrierung erfolgreich</h1>
      <p>Vielen Dank für Ihre Registrierung. </p>
      <p>Sie erhalten eine Mail mit einem Bestätigungslink. Bitte schauen Sie ggf. in Ihrem Spamordner nach.</p>
    </div>
  );
};

export default withRouter(RegisterSuccess);
