import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";
import "../File.css";
import vars from "../../utils/variables";
import FileUploadUser from "../UploadFile/FileUploadUser";

const ViewProfile = props => {
  const [userID, setUserID] = useState("");
  const [gender, setGender] = useState("");
  const [job, setJob] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [firm, setFirm] = useState("");
  const [language, setLanguage] = useState([0]);
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [tel, setTel] = useState("");
  const [telWork, setTelWork] = useState(""); /* toDo*/
  const [telPrivate, setTelPrivate] = useState(""); /* toDo*/
  const [emailWork, setEmailWork] = useState(""); /* toDo*/
  const [email, setEmail] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [getNotifications, setGetNotifications] = useState("");

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/users/myuser`, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setUserID(res.user._id);
          setJob(res.user.job);
          setFirstname(res.user.firstname);
          setLastname(res.user.lastname);
          setFirm(res.user.firm);
          setGender(res.user.gender);
          setLanguage(res.user.language);
          setStreet(res.user.street);
          setHouseNumber(res.user.houseNumber);
          setCity(res.user.city);
          setZipCode(res.user.zipCode);
          setTel(res.user.tel);
          setTelWork(res.user.telWork);
          setTelPrivate(res.user.telPrivate);
          setEmailWork(res.user.emailWork);
          setEmail(res.user.email);
          setGetNotifications(res.user.getNotifications);
          let bdate = new Date(res.user.birthdate * 1000).toLocaleDateString("de-DE");
          setBirthdate(bdate);
        } else {
          props.history.push("/login");
        }
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const languageValues = language.map((value, key) => <li key={key}>{value.val}</li>);

  return (
    <div className="medicalrecords view">
      <h1>Profil</h1>
      <div className="viewSection">
        <h2 className="captionFilled">Person</h2>
        <div className="innerSection">
          <div className="labelSection">Vorname:</div> <strong className="dataSection">{firstname}</strong>
          <br />
          <div className="labelSection">Nachname:</div> <strong className="dataSection">{lastname}</strong>
          <br />
          <div className="labelSection">Gender:</div> <strong className="dataSection">{gender}</strong>
          <br />
          <div className="labelSection">Geburtstag:</div> <strong className="dataSection">{birthdate}</strong>
          <br />
          <br />
          <div className="labelSection">Beschäftigung:</div> <strong className="dataSection">{job}</strong>
          <br />
          <div className="labelSection">Firma/Institution: </div>
          <strong className="dataSection">{firm}</strong>
          <br />
          <div className="language">
            <div className="labelSection">Sprachen: </div>
            <strong className="dataSection">
              <ul className="innerList">{languageValues}</ul>
            </strong>
          </div>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Kommunikation</h2>
        <div className="innerSection">
          <div className="labelSection">Mobil Telefon:</div> <strong className="dataSection">{tel}</strong>
          <br />
          <div className="labelSection">Privat Telefon:</div> <strong className="dataSection">{telPrivate}</strong>
          <br />
          <div className="labelSection">Arbeit Telefon:</div> <strong className="dataSection">{telWork}</strong>
          <br />
          <br />
          <div className="labelSection">Privat Email:</div> <strong className="dataSection">{email}</strong>
          <br />
          <div className="labelSection">Arbeit Email: </div>
          <strong className="dataSection">{emailWork}</strong>
          <br />
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Adresse</h2>
        <div className="innerSection">
          <div className="labelSection">Stadt:</div> <strong className="dataSection">{city}</strong>
          <br />
          <div className="labelSection">Postleitzahl:</div> <strong className="dataSection">{zipCode}</strong>
          <br />
          <div className="labelSection">Straße:</div> <strong className="dataSection">{street}</strong>
          <br />
          <div className="labelSection">Hausnummer:</div> <strong className="dataSection">{houseNumber}</strong>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Weiteres</h2>
        <div className="innerSection">
          <div className="labelSection">Passwort:</div>{" "}
          <strong className="dataSection">
            {" "}
            <Link to="/changepassword" className="link">
              Passwort ändern
            </Link>
          </strong>
          <br />
          <div className="labelSection">Benachrichtigung:</div>{" "}
          <strong className="dataSection">{getNotifications ? "Erhalten" : "Nicht erhalten"}</strong>
          <br />
        </div>
      </div>
      <div className="viewSection">
        <h2 className="captionFilled">Dokumente</h2>
        <div className="innerSection">
          <div className="labelSection">Pflichtdokument:</div>
          <strong className="dataSection">Verpflichtung auf Vertraulichkeit</strong>
          <br />
          <div className="labelSection">Gestattete Dateiformate:</div>
          <strong className="dataSection">.pdf .jpg .jpeg .JPG .JPEG</strong>
          <br />
          <br />
        </div>
        <br />
        <FileUploadUser userID={userID} showOnlyDocumentsNotUpload={false} />
      </div>
      <br />
      <Link
        to={{
          pathname: "/editprofile",
          state: {
            language: language,
            getNotifications: getNotifications
          }
        }}
      >
        <button type="button" className="inputRLSubmit">
          Bearbeiten
        </button>
      </Link>
      <Link to="/deleteprofile">
        <button type="button" className="inputRLSubmit delete">
          Löschen
        </button>
      </Link>
      <br />
    </div>
  );
};

export default withRouter(ViewProfile);
