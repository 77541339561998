import React from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";

const PostChangePassword = () => {
  return (
    <div className="formForm">
      <h1>Passwort geändert</h1>
      <p>Ihr Passwort wurde erfolgreich geändert.</p>
    </div>
  );
};

export default withRouter(PostChangePassword);
