import React from "react";
import vars from "../../utils/variables";
import "../File.css";

const DownloadFileUser = props => {
  const download = e => {
    window.open(`${vars.server}/userFileUpload/download/` + props.data.fileID);
  };

  return (
    <li className="inside">
      <div className="spaceOne mobileOne">{props.data.fileName}</div>
      <div className="spaceTwo mobileTwo">
        <button onClick={download} type="button" className="inputRLSubmit small">
          Download
        </button>
      </div>
    </li>
  );
};

export default DownloadFileUser;
