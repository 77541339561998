import React from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";

const NotLoggedIn = () => {
  return (
    <div className="formForm">
      <h2>
        Sie haben keinen Zugriff auf diese Seite. <br />
        Um fortzufahren melden Sie sich bitte ein.
      </h2>
      <Link to={{ pathname: "/login" }}>
        <button type="button" className="inputRLSubmit">
          LogIn
        </button>
      </Link>
    </div>
  );
};

export default withRouter(NotLoggedIn);
