import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";
import useForm from "react-hook-form";
import vars from "../../utils/variables";
import { emailPattern } from "../Pattern/Pattern";

const Login = ({ history, setAuth, auth, fetchPermissions }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [confirmEmailAgain, setConfirmEmailAgain] = useState(false);

  useEffect(() => {
    if (auth) history.push("/dashboard");
  }, [auth, history]);

  const { register, handleSubmit, errors } = useForm();

  const handleFetch = () => {
    fetch(`${vars.server}/users/login`, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.success) {
          fetchPermissions();
          if (!result.filesChecked) {
            history.push("/usernotaccepted");
          }
          setAuth(true);
        } else {
          setError(result.message);
          if (result.emailNotVerified) {
            setConfirmEmailAgain(true);
          }
        }
      });
  };

  const sendConfirmationMail = () => {
    fetch(`${vars.server}/requestnewemail/requestnewemail`, {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          setError("");
          setSuccessMessage("Die Bestätigungsemail wurde erneut an Ihre Email-Adresse verschickt");
        }
      });
  };

  const handleChangeEmail = event => setEmail(event.target.value);
  const handleChangePassword = event => setPassword(event.target.value);

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Anmelden</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      {successMessage && <p className="successMessage"> Erfolg: {successMessage}</p>}
      <label className="labelRL">
        Email*
        <input
          type="text"
          defaultValue={email}
          onChange={handleChangeEmail}
          name="emailName"
          ref={register({
            required: true,
            pattern: emailPattern
          })}
          className="inputRL"
        />
      </label>
      {errors.emailName && errors.emailName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.emailName && errors.emailName.type === "pattern" && <p className="error">Invalide Mail Adresse</p>}
      <label className="labelRL">
        Passwort*
        <input
          type="password"
          defaultValue={password}
          onChange={handleChangePassword}
          name="passwordName"
          ref={register({ required: true })}
          className="inputRL"
        />
      </label>
      {errors.passwordName && <p className="error">Pflichtfeld</p>}
      <br />
      <br />
      <Link to="/forgotpassword" className="link">
        Passwort vergessen
      </Link>
      <br />
      <input type="submit" value="Anmelden" className="inputRLSubmit" />
      {confirmEmailAgain && (
        <Link to="/login">
          <button type="button" onClick={sendConfirmationMail} className="inputRLSubmit">
            Bestätigungsmail erneut zusenden
          </button>
        </Link>
      )}
    </form>
  );
};

export default withRouter(Login);
