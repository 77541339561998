import React, { Fragment, useState, useEffect } from "react";
import Progress from "./Progress";
import axios from "axios";
import vars from "../../utils/variables";
import DownloadFileUser from "./DownloadFileUser";
import "../Form.css";

const FileUploadUser = props => {
  const userID = props.userID;
  const showOnlyDocumentsNotUpload = props.showOnlyDocumentsNotUpload;
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState("");
  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [api, setApi] = useState({ files: [] });

  async function fetchData() {
    const res = await fetch(`${vars.server}/userFileUpload/getFiles/` + userID, {
      credentials: "include"
    });
    res.json().then(res => setApi(res));
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchFiles = setInterval(() => fetchData(), 5000);
    return () => clearInterval(fetchFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateFile = fileName => {
    var ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (ext === "pdf" || ext === "jpg" || ext === "jpeg" || ext === "JPG" || ext === "JPEG") {
      return true;
    } else {
      return false;
    }
  };

  const onChange = e => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setValidated(validateFile(e.target.files[0].name));
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios
        .post(vars.server + "/userFileUpload/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          withCredentials: true,
          onUploadProgress: progressEvent => {
            setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
            // Clear percentage
            setTimeout(() => setUploadPercentage(0), 10000);
          }
        })
        .then(response => {
          return response.json();
        });
      const { fileName, filePath } = res.data;
      setUploadedFile({ fileName, filePath });
    } catch (err) {
      if (err === 500) {
        //("There was a problem")
      } else {
        ///"(No error)"
      }
    }
  };

  return (
    <Fragment>
      {!showOnlyDocumentsNotUpload ? (
        <form onSubmit={onSubmit}>
          <div>
            <input type="file" className="inputRLSubmit delete small full" id="customFile" onChange={onChange} />
            <label htmlFor="customFile"></label>
          </div>
          <h1 hidden={!validated}>
            <Progress percentage={uploadPercentage} />
          </h1>
          <input type={validated ? "submit" : "hidden"} value="Upload" className="inputRLSubmit delete full" />
        </form>
      ) : null}
      {uploadedFile ? (
        <div>
          <h3>{uploadedFile.fileName}</h3>
          <img src={uploadedFile.filePath} alt="" />
        </div>
      ) : null}
      <ul className="tableHead">
        <li className="spaceOne mobileOne">Dateiname</li>
        <li className="spaceTwo mobileTwo">Zugriff</li>
      </ul>
      <ul>
        {api.files.map((file, index) => (
          <DownloadFileUser key={index} data={file} />
        ))}
      </ul>
    </Fragment>
  );
};

export default FileUploadUser;
