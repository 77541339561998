import React from "react";

const ToggleSwitch = ({ isOn, handleToggle, label }) => {
  return (
    <>
      <label className="labelRL container big">
        {" "}
        {label}
        <input checked={isOn} onChange={handleToggle} type="checkbox" />
        <span className="checkmark"></span>
      </label>
    </>
  );
};

export default ToggleSwitch;
