import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import useForm from "react-hook-form";
import vars from "../../utils/variables";
import { emailPattern } from "../Pattern/Pattern";

const Forgotpassword = ({ history }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const handleFetch = () => {
    fetch(`${vars.server}/forgotpassword/forgotpassword`, {
      method: "POST", // or 'PUT'
      body: JSON.stringify({ email }), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.success) {
          history.push("/login");
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangeEmail = event => setEmail(event.target.value);

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h2>E-Mail angeben und Passwort zurücksetzen</h2>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <label className="labelRL">
        E-Mail*
        <input
          type="text"
          defaultValue={email}
          onChange={handleChangeEmail}
          name="emailName"
          ref={register({
            required: true,
            pattern: emailPattern
          })}
          className="inputRL"
          placeholder="max.mustermann@mail.de"
        />
      </label>
      {errors.emailName && errors.emailName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.emailName && errors.emailName.type === "pattern" && <p className="error">Invalide Mail Adresse</p>}
      <br />
      <br />
      <input type="submit" value="Passwort zurücksetzen" className="inputRLSubmit" />
    </form>
  );
};

export default withRouter(Forgotpassword);
