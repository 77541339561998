import React from "react";
import { Link } from "react-router-dom";

const User = props => {
  return (
    <li className="inside">
      <div className="spaceOne">
        {props.data.firstname}
        <br />
        {props.data.lastname}
        <br />
        {props.data.gender}
      </div>
      <div className="spaceTwo">{props.data.job}</div>
      <div className="spaceThree">
        {props.data.tel} <br />
        {props.data.telPrivate} <br />
        {props.data.telWork} <br />
        {props.data.email} <br />
        {props.data.emailWork}
      </div>
      <div className="spaceFour">
        <Link
          to={{
            pathname: "/viewuser",
            state: { id: props.data._id }
          }}
        >
          <button type="button" className="inputRLSubmit small">
            Einsehen
          </button>
        </Link>
      </div>
    </li>
  );
};

export default User;
