import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";
import "../File.css";
import MedicalRecord from "../MedicalRecord/MedicalRecord";
import vars from "../../utils/variables";

const MedicalRecords = props => {
  const [api, setApi] = useState({ patientfiles: [] });

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/patientfiles/user`, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setApi(res);
        } else {
          props.history.push("/login");
        }
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="medicalrecords">
      <h1>Betreute Patientenakten</h1>
      <ul className="tableHead">
        <li className="spaceOne">Name</li>
        <li className="spaceTwo">Sprachen</li>
        <li className="spaceThree">Kommunikation</li>
        <li className="spaceFour">Zugriff</li>
      </ul>
      <ul>
        {api.patientfiles.map((patientfile, index) => (
          <MedicalRecord key={index} data={patientfile} />
        ))}
      </ul>
      {props.permissions.createDeletePatientfile && (
        <Link to="/createmedicalrecord">
          <button type="button" className="inputRLSubmit">
            Patientenakte erstellen
          </button>
        </Link>
      )}
    </div>
  );
};

export default withRouter(MedicalRecords);
