import React from "react";
import "./Chat.css";

const ChatMessage = props => {
  let messageDate = new Date(props.data.chatMessageDate).toLocaleDateString("de-DE");
  let messageTime = new Date(props.data.chatMessageDate).toLocaleTimeString("de-DE");

  return (
    <li>
      <strong>{props.data.chatMessageAuthorName}</strong>{" "}
      <small>
        {messageDate} - {messageTime} Uhr
      </small>
      <br />
      {props.data.chatMessage}
      <br />
      <br />
      <br />
    </li>
  );
};

export default ChatMessage;
