import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import useForm from "react-hook-form";
import vars from "../../utils/variables";

const LeaveMedicalRecord = ({ history }) => {
  const id = history.location.state.id;
  const [error, setError] = useState(false);

  const { handleSubmit } = useForm();

  const handleFetch = () => {
    fetch(`${vars.server}/exchange/quitPatientfile/` + id, {
      method: "POST",
      body: JSON.stringify(),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.success) {
          history.push("/dashboard");
        } else {
          setError(result.message);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Patientenakte verlassen</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <p>Um aus der Patientenakte vollständig auszutreten, bestätigen Sie bitte mit "Patientenakte austreten".</p>
      <br />
      <br />
      <input type="submit" value="Patientenakte austreten" className="inputRLSubmit delete" />
    </form>
  );
};

export default withRouter(LeaveMedicalRecord);
