import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import "./Navbar.css";
import logo from "./Logo.PNG";
import vars from "../../utils/variables";

const Navbar = props => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const logoutButton = () => {
    fetch(`${vars.server}/users/logout`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          props.setAuth(false);
          props.history.push("/login");
        }
      });
  };

  return (
    <nav className="navBar">
      <a href="http://msfr.bplaced.net/wordpress/">
        <img className="logo logoImg" src={logo} alt="" />
      </a>
      <ul className={navbarOpen ? "navLinks nav-active" : "navLinks"}>
        <li onClick={() => setNavbarOpen(false)}>
          <Link to="/contact" className="navHover">
            Kontakt
          </Link>
        </li>
        {props.auth ? (
          <>
            {props.permissions.filesChecked && props.permissions.getAndEnterAllOpenAppointments && (
              <li onClick={() => setNavbarOpen(false)}>
                <Link to="/exchange" className="navHover">
                  Fallbörse
                </Link>
              </li>
            )}
            {props.permissions.filesChecked && (
              <li onClick={() => setNavbarOpen(false)}>
                <Link to="/myappointments" className="navHover">
                  Termine
                </Link>
              </li>
            )}
            {props.permissions.filesChecked && (
              <li onClick={() => setNavbarOpen(false)}>
                <Link to="/medicalrecords" className="navHover">
                  Patientenakten
                </Link>
              </li>
            )}
            <li onClick={() => setNavbarOpen(false)}>
              <Link to="/viewprofile" className="navHover">
                Profil
              </Link>
            </li>
            {props.permissions.isAdmin && (
              <li onClick={() => setNavbarOpen(false)}>
                <Link to="/useradministration" className="navHover">
                  Administration
                </Link>
              </li>
            )}
            <li onClick={() => setNavbarOpen(false)}>
              <Link to="/login" className="navHover">
                <button type="button" onClick={logoutButton} className="buttonLogout">
                  Abmelden
                </button>
              </Link>
            </li>
          </>
        ) : (
          <>
            <li onClick={() => setNavbarOpen(false)}>
              <Link to="/login" className="navHover">
                Anmelden
              </Link>
            </li>
            <li onClick={() => setNavbarOpen(false)}>
              <Link to="/register" className="navHover">
                Registrieren
              </Link>
            </li>
          </>
        )}
      </ul>
      <div className={navbarOpen ? "burger toggle" : "burger"} onClick={() => setNavbarOpen(!navbarOpen)}>
        <div className="one"></div>
        <div className="two"></div>
        <div className="three"></div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
