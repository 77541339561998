import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../../Form.css";
import useForm from "react-hook-form";
import vars from "../../../utils/variables";

const DeleteUser = ({ history }) => {
  const id = history.location.state.id;
  const [oldPassword, setOldPassword] = useState("");
  const [error, setError] = useState(false);
  
  const { register, handleSubmit, errors } = useForm();

  const handleFetch = () => {
    fetch(`${vars.server}/administration/deleteuser/` + id, {
      method: "POST", // or 'PUT'
      body: JSON.stringify({ oldPassword }), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.success) {
          history.push("/useradministration");
        } else {
          setError(result.message);
        }
        //props.refetchAPI()
      });
  };

  const handleChangeOldPassword = event => setOldPassword(event.target.value);

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Nutzer löschen</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <p>
        Um den Nutzer vollständig und unwiderruflich zu löschen, geben Sie Ihr Passwort ein und bestätigen Sie mit
        "Nutzer löschen".
      </p>
      <br />
      <label className="labelRL">
        Ihr Passwort*
        <input
          type="password"
          defaultValue={oldPassword}
          onChange={handleChangeOldPassword}
          name="passwordName"
          ref={register({ required: true })}
          className="inputRL"
          placeholder="Passwort"
        />
      </label>
      {errors.passwordName && <p className="error">Pflichtfeld</p>}
      <br />
      <br />
      <input type="submit" value="Nutzer löschen" className="inputRLSubmit delete" />
    </form>
  );
};

export default withRouter(DeleteUser);
