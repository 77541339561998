import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import "../File.css";
import Appointment from "../Appointment/Appointment";
import vars from "../../utils/variables";

const Appointments = props => {
  const id = props.id;
  const [api, setApi] = useState({ appointments: [] });

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/appointments/patientfile/` + id, {
        credentials: "include"
      });
      res.json().then(res => setApi(res));
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ul className="tableHead">
        <li className="spaceOne">Datum</li>
        <li className="spaceTwo">Adresse</li>
        <li className="spaceThree">Beschreibung</li>
        <li className="spaceFour">Zugriff</li>
      </ul>
      <ul>
        {api.appointments.map((appointment, index) => (
          <Appointment key={index} data={appointment} />
        ))}
      </ul>
    </div>
  );
};

export default withRouter(Appointments);
