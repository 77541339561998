import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export const Footer = () => {
  return (
    <footer>
      <strong>MEDICAL STUDENTS FOR REFUGEES</strong>
      <br />
      <br />
      <ul>
        <li>
          <Link to="/">Nutzungsbedingungen</Link>
        </li>
        <li>
          <Link to="/">Impressum</Link>
        </li>
        <li>Copyright &copy; 2020. All rights reserved.</li>
      </ul>
    </footer>
  );
};

export default Footer;
