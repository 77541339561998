import React from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";

const UserNotAccepted = props => {
  return (
    <div className="formForm">
      <h2>Ihr Account wurde noch nicht bestätigt</h2>
      <br />
      <p>
        Bitte vervollständigen Sie Ihr Profil, damit alle Funktionen freigeschaltet werden und Zugriff auf Termine sowie
        Patientenakten gewährt wird.
      </p>
      <br />
      <p>
        Dazu gehört der Upload der Datenschutzdokumente und ggf. die Ergänzung von Sprachkenntnissen in Ihrem Profil.
      </p>
      <br />
      <p>
        Falls Sie Ihr Profil schon vervollständigt haben, können Sie diese Meldung ignorieren und müssen auf die
        Freischaltung eines Admins warten.
      </p>
      <br />
      <p>Bei weiteren Fragen können Sie gerne unser Kontaktformular nutzen. </p>
      {props.auth && (
        <Link to={{ pathname: "/viewprofile" }}>
          <button type="button" className="inputRLSubmit">
            Zu Ihrem Profil
          </button>
        </Link>
      )}
    </div>
  );
};

export default withRouter(UserNotAccepted);
