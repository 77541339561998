//Filterbar Soz Dol Admin und Med
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../Administration.css";
import "../../Form.css";
import "../../File.css";
import User from "./User";
import NavbarAdministration from "../NavbarAdministration";
import vars from "../../../utils/variables";

const UserAdministration = props => {
  const [api, setApi] = useState({ users: [] });
  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/administration/allusers`, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setApi(res);
        } else if (res.subject === "NotAuthenticated") {
          props.history.push("/login");
        } else if (res.subject === "NotAuthorized") {
          props.history.push("/notauthorized");
        }
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Administration">
      <NavbarAdministration />
      <h1>Alle Nutzer</h1>
      <div className="Admin">
        <ul className="tableHead">
          <li className="spaceOne">Name</li>
          <li className="spaceTwo">Typ</li>
          <li className="spaceThree">Kommunikation</li>
          <li className="spaceFour">Zugriff</li>
        </ul>
        <ul>
          {api.users.map((user, index) => (
            <User key={index} data={user} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(UserAdministration);
