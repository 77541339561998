import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import useForm from "react-hook-form";
import vars from "../../utils/variables";

const DeleteProfile = ({ history }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [error, setError] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const handleFetch = () => {
    fetch(`${vars.server}/users/delete`, {
      method: "POST",
      body: JSON.stringify({ oldPassword }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.success) {
          window.location.href = "http://msfr.bplaced.net/wordpress";
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangeOldPassword = event => setOldPassword(event.target.value);

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Profil löschen</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <p>
        Um Ihr Profil vollständig und unwiderruflich zu löschen, geben Sie Ihr Passwort ein und bestätigen Sie mit
        "Profil löschen".
      </p>
      <br />
      <label className="labelRL">
        Ihr Passwort*
        <input
          type="password"
          defaultValue={oldPassword}
          onChange={handleChangeOldPassword}
          name="passwordName"
          ref={register({ required: true })}
          className="inputRL"
          placeholder="Passwort"
        />
      </label>
      {errors.passwordName && <p className="error">Pflichtfeld</p>}
      <br />
      <br />
      <input type="submit" value="Profil löschen" className="inputRLSubmit delete" />
    </form>
  );
};

export default withRouter(DeleteProfile);
