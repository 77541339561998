import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import "./Dashboard.css";
import vars from "../../utils/variables";
import logo from "../Header/Logo.PNG";

const Home = props => {
  useEffect(() => {
    if (!props.auth) props.history.push("/login");
  }, [props.auth, props.history]);

  useEffect(() => {
    fetch(`${vars.server}/users/username`, {
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          props.setUsername(res.username);
        }
      });
  }, [props]);

  return (
    <div className="formForm dashboard">
      <h1>
        <p className="caption">
          Medical Students
          <br /> For Refugees
        </p>
      </h1>
      <br />
      <img className="logoDashboard" src={logo} alt="" />
      <br />
      <br />
      <p className="name">
        Herzlich Willkommen
        <br />
        {props.username}
      </p>
    </div>
  );
};

export default withRouter(Home);
