import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";
import useForm from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LanguageComponent from "../FormComponents/LanguageComponent";
import { namePattern, telPattern, emailPattern } from "../Pattern/Pattern";
import vars from "../../utils/variables";

const EditProfile = props => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [firm, setFirm] = useState("");
  const [oldLanguage, setOldLanguage] = useState(props.location.state.language);
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [tel, setTel] = useState("");
  const [telWork, setTelWork] = useState("");
  const [telPrivate, setTelPrivate] = useState("");
  const [emailWork, setEmailWork] = useState("");
  const [email, setEmail] = useState("");
  const [bdate, setBirthdate] = useState("");
  const [getNotifications, setGetNotifications] = useState(props.location.state.getNotifications);
  const [bdateOld, setBdateOld] = useState("");
  const [error, setError] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/users/myuser`, {
        credentials: "include"
      });
      res.json().then(res => {
        setFirstname(res.user.firstname);
        setLastname(res.user.lastname);
        setFirm(res.user.firm);
        setOldLanguage(res.user.language);
        setStreet(res.user.street);
        setHouseNumber(res.user.houseNumber);
        setCity(res.user.city);
        setZipCode(res.user.zipCode);
        setTel(res.user.tel);
        setTelWork(res.user.telWork);
        setTelPrivate(res.user.telPrivate);
        setEmailWork(res.user.emailWork);
        setEmail(res.user.email);
        setBirthdate(res.user.birthdate * 1000);
        setGetNotifications(res.user.getNotifications);
        setBdateOld(res.user.birthdate * 1000);
      });
    }
    fetchData();
  }, []);

  const handleFetch = () => {
    let birthdate;
    if (bdate === bdateOld) {
      birthdate = bdate / 1000;
    } else if (bdate != null) {
      birthdate = bdate.getTime() / 1000;
    } else {
      birthdate = "";
    }
    for (var i = languageState.length - 1; i >= 0; i--) {
      if (!languageState[i].val) {
        languageState.splice(i, 1);
      }
    }
    let language = oldLanguage.concat(languageState);

    fetch(`${vars.server}/users/edit`, {
      method: "POST",
      body: JSON.stringify({
        firstname,
        lastname,
        firm,
        street,
        houseNumber,
        city,
        zipCode,
        tel,
        telWork,
        telPrivate,
        emailWork,
        email,
        birthdate,
        language,
        getNotifications
      }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          props.history.push("/viewprofile");
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangeFirstName = event => setFirstname(event.target.value);
  const handleChangeLastName = event => setLastname(event.target.value);
  const handleChangeFirm = event => setFirm(event.target.value);
  const handleChangeStreet = event => setStreet(event.target.value);
  const handleChangeHouseNumber = event => setHouseNumber(event.target.value);
  const handleChangeCity = event => setCity(event.target.value);
  const handleChangeZipCode = event => setZipCode(event.target.value);
  const handleChangeTel = event => setTel(event.target.value);
  const handleChangeTelPrivate = event => setTelPrivate(event.target.value);
  const handleChangeTelWork = event => setTelWork(event.target.value);
  const handleChangeEmailWork = event => setEmailWork(event.target.value);
  const handleChangeEmail = event => setEmail(event.target.value);
  const handleChangeGetNotifications = event => setGetNotifications(event.target.value);

  //Language
  const blankLanguage = { val: "" };
  const [languageState, setLanguageState] = useState([{ ...blankLanguage }]);
  const addLanguage = () => {
    setLanguageState([...languageState, { ...blankLanguage }]);
  };
  const handleLanguageState = e => {
    const updatedLangugaes = [...languageState];
    updatedLangugaes[e.target.dataset.idx][e.target.className] = e.target.value;
    setLanguageState(updatedLangugaes);
  };
  const oldLanguageValues = oldLanguage.map((value, key) => <li key={key}>{value.val}</li>);

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Profil bearbeiten</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <h2>Person</h2>
      <label className="labelRL">
        Vorname
        <input
          type="text"
          defaultValue={firstname}
          onChange={handleChangeFirstName}
          name="nameFirstName"
          ref={register({
            required: true,
            pattern: namePattern
          })}
          className="inputRL"
          placeholder="Vorname"
        />
      </label>
      {errors.nameFirstName && errors.nameFirstName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameFirstName && errors.nameFirstName.type === "pattern" && <p className="error">Invalider Vorname</p>}
      <label className="labelRL">
        Nachname
        <input
          type="text"
          defaultValue={lastname}
          onChange={handleChangeLastName}
          name="nameLastName"
          ref={register({
            required: true,
            pattern: namePattern
          })}
          className="inputRL"
          placeholder="Nachname"
        />
      </label>
      {errors.nameLastName && errors.nameLastName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameLastName && errors.nameLastName.type === "pattern" && <p className="error">Invalider Nachname</p>}
      <label className="labelRL">
        Geburtsdatum
        <br />
        <DatePicker
          selected={bdate}
          onChange={date => setBirthdate(date)}
          dateFormat="dd.MM.yyyy"
          placeholderText="tt.mm.jjjj"
          required
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="inputRL"
        />
      </label>
      <label className="labelRL">
        Firma/Institution
        <input
          type="text"
          defaultValue={firm}
          onChange={handleChangeFirm}
          name="nameFirm"
          ref={register({ required: true })}
          className="inputRL"
          placeholder="Firma/Institution"
        />
      </label>
      {errors.nameFirm && <p className="error">Pflichtfeld</p>}
      <label className="labelRL">
        Bisherige Sprachen
        <ul className="boxList"> {oldLanguageValues}</ul>
        <br />
        <LanguageComponent
          addLanguage={addLanguage}
          languageState={languageState}
          handleLanguageState={handleLanguageState}
        />
      </label>
      <h2>Adresse</h2>
      <label className="labelRL">
        Straße
        <input
          type="text"
          defaultValue={street}
          onChange={handleChangeStreet}
          name="nameStreet"
          className="inputRL"
          placeholder="Straße"
        />
      </label>
      <label className="labelRL">
        Hausnummer
        <input
          type="text"
          defaultValue={houseNumber}
          onChange={handleChangeHouseNumber}
          name="nameHouseNumber"
          className="inputRL"
          placeholder="Hausnummer"
        />
      </label>
      <label className="labelRL">
        Stadt
        <input
          type="text"
          defaultValue={city}
          onChange={handleChangeCity}
          name="nameCity"
          className="inputRL"
          placeholder="Stadt"
        />
      </label>
      <label className="labelRL">
        Postleitzahl
        <input
          type="text"
          defaultValue={zipCode}
          onChange={handleChangeZipCode}
          name="nameZipCode"
          className="inputRL"
          placeholder="Postleitzahl"
        />
      </label>
      <h2>Kommunikation</h2>
      <label className="labelRL">
        Telefon Mobil
        <input
          type="tel"
          defaultValue={tel}
          onChange={handleChangeTel}
          name="nameTel"
          ref={register({
            required: true,
            pattern: telPattern
          })}
          className="inputRL"
          placeholder="Telefonnummer"
        />
      </label>
      {errors.nameTel && errors.nameTel.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameTel && errors.nameTel.type === "pattern" && <p className="error">Invalide Telefonnummer</p>}
      <label className="labelRL">
        Telefon Privat
        <input
          type="tel"
          defaultValue={telPrivate}
          onChange={handleChangeTelPrivate}
          name="nameTelPrivate"
          ref={register({
            pattern: telPattern
          })}
          className="inputRL"
          placeholder="Telefonnummer"
        />
      </label>
      {errors.nameTelMobile && errors.nameTelMobile.type === "pattern" && (
        <p className="error">Invalide Telefonnummer</p>
      )}
      <label className="labelRL">
        Telefon Arbeit
        <input
          type="tel"
          defaultValue={telWork}
          onChange={handleChangeTelWork}
          name="nameTel"
          ref={register({
            pattern: telPattern
          })}
          className="inputRL"
          placeholder="Telefonnummer"
        />
      </label>
      {errors.nameTelWork && errors.nameTelWork.type === "pattern" && <p className="error">Invalide Telefonnummer</p>}
      <label className="labelRL">
        Email
        <input
          type="text"
          defaultValue={email}
          onChange={handleChangeEmail}
          name="emailName"
          ref={register({
            required: true,
            pattern: emailPattern
          })}
          className="inputRL"
          placeholder="max.mustermann@mail.de"
        />
      </label>
      {errors.emailName && errors.emailName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.emailName && errors.emailName.type === "pattern" && <p className="error">Invalide Mail Adresse</p>}
      <label className="labelRL">
        Email Arbeit
        <input
          type="text"
          defaultValue={emailWork}
          onChange={handleChangeEmailWork}
          name="emailNameWork"
          ref={register({
            pattern: emailPattern
          })}
          className="inputRL"
          placeholder="max.mustermann@mail.de"
        />
      </label>
      {errors.emailNameWork && errors.emailNameWork.type === "pattern" && (
        <p className="error">Invalide Mail Adresse</p>
      )}
      <h2>Weitere Einstellungen</h2>
      <label className="labelRL">Passwort</label>
      <Link to="/changepassword" className="link">
        Passwort ändern
      </Link>
      <label className="labelRL">
        Benachrichtigungsintervall
        <select
          defaultValue={getNotifications}
          onChange={handleChangeGetNotifications}
          name="nameNotification"
          ref={register({ required: true })}
          className="inputRL"
        >
          <option value="true">Benachrichtigungen erhalten</option>
          <option value="false">Keine Benachrichtigungen erhalten</option>
        </select>
      </label>
      {errors.nameNotification && <p className="error">Pflichtfeld</p>}
      <input type="submit" value="Speichern" className="inputRLSubmit" />
    </form>
  );
};

export default withRouter(EditProfile);
