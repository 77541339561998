import React, { Component } from "react";

class LanguageOptions extends Component {
  render() {
    return (
      <select
        val={this.props.val}
        data-idx={this.props.dataIdx}
        id={this.props.id}
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        <option value="">-</option>
        <option value="Albanisch"> Albanisch </option>
        <option value="Arabisch"> Arabisch </option>
        <option value="Armenisch"> Armenisch </option>
        <option value="Aserbeidschanisch"> Aserbeidschanisch </option>
        <option value="Bengali"> Bengali </option>
        <option value="Bulgarisch"> Bulgarisch </option>
        <option value="Chinesisch"> Chinesisch </option>
        <option value="Dänisch"> Dänisch </option>
        <option value="Deutsch"> Deutsch </option>
        <option value="Englisch"> Englisch </option>
        <option value="Finnisch"> Finnisch </option>
        <option value="Französisch"> Französisch </option>
        <option value="Georgisch"> Georgisch </option>
        <option value="Griechisch"> Griechisch </option>
        <option value="Hindi"> Hindi </option>
        <option value="Italienisch"> Italienisch </option>
        <option value="Japanisch"> Japanisch </option>
        <option value="Kurdisch-Kurmanc"> Kurdisch-Kurmanc </option>
        <option value="Kurdisch-Sorani"> Kurdisch-Sorani </option>
        <option value="Luxemburgisch"> Luxemburgisch </option>
        <option value="Malinke-Sprache"> Malinke-Sprache </option>
        <option value="Mongolisch"> Mongolisch </option>
        <option value="Paschtu"> Paschtu </option>
        <option value="Persisch"> Persisch </option>
        <option value="Polnisch"> Polnisch </option>
        <option value="Portugiesisch"> Portugiesisch </option>
        <option value="Rumänisch"> Rumänisch </option>
        <option value="Russisch"> Russisch </option>
        <option value="Serbisch"> Serbisch </option>
        <option value="Somali"> Somali </option>
        <option value="Spanisch"> Spanisch </option>
        <option value="Tschetschenisch"> Tschetschenisch </option>
        <option value="Türkisch"> Türkisch </option>
        <option value="Vietnamesisch"> Vietnamesisch </option>
        <option value="Weißrussisch"> Weißrussisch </option>
      </select>
    );
  }
}

export default LanguageOptions;
