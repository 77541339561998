import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../Administration.css";
import "../../Form.css";
import "../../File.css";
import MedicalRecord from "../../MedicalRecord/MedicalRecord";
import User from "../AdminUser/User";
import NavbarAdministration from "../NavbarAdministration";
import vars from "../../../utils/variables";

const AdminRequests = props => {
  const [api, setApi] = useState({ patientfiles: [] });
  const [api2, setApi2] = useState({ users: [] });

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/administration/getUnconfirmedUsers`, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setApi2(res);
        } else if (res.subject === "NotAuthenticated") {
          props.history.push("/login");
        } else if (res.subject === "NotAuthorized") {
          props.history.push("/notauthorized");
        }
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/administration/getUnconfirmedPatientfiles`, {
        credentials: "include"
      });
      res.json().then(res => setApi(res));
    }
    fetchData();
  }, []);

  return (
    <div className="Administration">
      <NavbarAdministration />
      <h1>Anfragen</h1>
      <div className="Admin">
        <div className="viewSection">
          <h2 className="captionFilled">Unbestätigte Nutzer</h2>
          <ul className="tableHead">
            <li className="spaceOne">Name</li>
            <li className="spaceTwo">Typ</li>
            <li className="spaceThree">Kommunikation</li>
            <li className="spaceFour">Zugriff</li>
          </ul>
          <ul>
            {api2.users.map((user, index) => (
              <User key={index} data={user} />
            ))}
          </ul>
        </div>
        <div className="viewSection">
          <h2 className="captionFilled">Unbestätigte Patientenakten</h2>
          <ul className="tableHead">
            <li className="spaceOne">Name</li>
            <li className="spaceTwo">Sprache</li>
            <li className="spaceThree">Kommunikation</li>
            <li className="spaceFour">Zugriff</li>
          </ul>
          <ul>
            {api.patientfiles.map((patientfile, index) => (
              <MedicalRecord key={index} data={patientfile} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AdminRequests);
