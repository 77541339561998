import React from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";

const NotAuthorized = () => {
  return (
    <div className="formForm">
      <h2>Sie haben keine Berechtigung auf diese Seite zuzugreifen.</h2>
      <Link to={{ pathname: "/dashboard" }}>
        <button type="button" className="inputRLSubmit">
          Übersicht
        </button>
      </Link>
    </div>
  );
};

export default withRouter(NotAuthorized);
