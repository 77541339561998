import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import useForm from "react-hook-form";
import vars from "../../utils/variables";

const Resetpassword = props => {
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");

  const { register, handleSubmit, watch, errors } = useForm();

  const [error, setError] = useState(false);
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const resetID = props.location;
  const token = resetID.search.slice(4);

  const handleFetch = () => {
    fetch(`${vars.server}/resetpassword/resetpassword/${token}`, {
      method: "POST",
      body: JSON.stringify({
        password,
        confirmationPassword
      }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          props.history.push("/login");
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangePassword = event => setPassword(event.target.value);
  const handleChangeConfirmationPassword = event => setConfirmationPassword(event.target.value);
  const validatePassword = async value => {
    await sleep(500);
    return value === watch("namePassword1");
  };

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Ihr neues Passwort</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <label className="labelRL">
        Passwort*
        <input
          type="password"
          defaultValue={password}
          onChange={handleChangePassword}
          name="namePassword1"
          ref={register({ required: true, minLength: 10 })}
          className="inputRL"
          placeholder="Passwort"
        />
      </label>
      {errors.namePassword1 && errors.namePassword1.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.namePassword1 && errors.namePassword1.type === "minLength" && (
        <p className="error">Passwort muss mindestens 10 Zeichen lang sein</p>
      )}
      <label className="labelRL">
        Passwort bestätigen*
        <input
          type="password"
          defaultValue={confirmationPassword}
          onChange={handleChangeConfirmationPassword}
          name="namePassword2"
          ref={register({
            required: true,
            validate: validatePassword
          })}
          className="inputRL"
          placeholder="Passwort wiederholen"
        />
      </label>
      {errors.namePassword2 && errors.namePassword2.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.namePassword2 && errors.namePassword2.type === "validate" && (
        <p className="error">Passwörter stimmen nicht überein</p>
      )}
      <input type="submit" name="nameSubmit" value="Passwort aktualisieren" className="inputRLSubmit" />
    </form>
  );
};

export default withRouter(Resetpassword);
