import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import "../Form.css";
import useForm from "react-hook-form";
import vars from "../../utils/variables";

const ChangePassword = ({ history }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm();

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const handleFetch = () => {
    fetch(`${vars.server}/users/changepassword`, {
      method: "POST",
      body: JSON.stringify({ oldPassword, newPassword, confirmNewPassword }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.success) {
          history.push("/postchangepassword");
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangeOldPassword = event => setOldPassword(event.target.value);
  const handleChangeNewPassword = event => setNewPassword(event.target.value);
  const handleChangeConfirmNewPassword = event => setConfirmNewPassword(event.target.value);

  const validatePassword = async value => {
    await sleep(500);
    return value === watch("passwordName1");
  };

  return (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Passwort ändern</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <label className="labelRL">
        Ihr altes Passwort*
        <input
          type="password"
          defaultValue={oldPassword}
          onChange={handleChangeOldPassword}
          name="passwordName"
          ref={register({ required: true })}
          className="inputRL"
          placeholder="Ihr altes Passwort"
        />
      </label>
      {errors.passwordName && <p className="error">Pflichtfeld</p>}
      <label className="labelRL">
        Ihr neues Passwort*
        <input
          type="password"
          defaultValue={newPassword}
          onChange={handleChangeNewPassword}
          name="passwordName1"
          ref={register({ required: true, minLength: 10 })}
          className="inputRL"
          placeholder="Ihr neues Passwort"
        />
      </label>
      {errors.passwordName1 && errors.passwordName1.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.passwordName1 && errors.passwordName1.type === "minLength" && (
        <p className="error">Passwort muss mindestens 10 Zeichen lang sein</p>
      )}
      <label className="labelRL">
        Bestätigen Sie ihr neues Passwort*
        <input
          type="password"
          defaultValue={confirmNewPassword}
          onChange={handleChangeConfirmNewPassword}
          name="passwordName2"
          ref={register({ required: true, validate: validatePassword })}
          className="inputRL"
          placeholder="Ihr neues Passwort"
        />
      </label>
      {errors.passwordName2 && errors.passwordName2.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.passwordName2 && errors.passwordName2.type === "validate" && (
        <p className="error">Passwörter stimmen nicht überein</p>
      )}
      <br />
      <br />
      <Link to="/forgotpassword" className="link">
        Passwort vergessen
      </Link>
      <br />
      <input type="submit" value="Ändern" className="inputRLSubmit" />
    </form>
  );
};

export default withRouter(ChangePassword);
