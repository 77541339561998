import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import vars from "../../utils/variables";

const ConfirmEmail = props => {
  const resetID = props.location;
  const token = resetID.search.slice(4);

  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/confirmemail/${token}`, {
        method: "post",
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          props.history.push("/login");
        } else {
          setError(res.message);
        }
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <p>Seite lädt...</p>
    </div>
  );
};

export default withRouter(ConfirmEmail);
