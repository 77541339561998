import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import "../File.css";
import vars from "../../utils/variables";
import AppointmentsExchange from "./Appointmentsexchange";

const Exchange = props => {
  const [api, setApi] = useState({ appointments: [] });

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${vars.server}/exchange/open`, {
        credentials: "include"
      });
      res.json().then(res => {
        if (res.success) {
          setApi(res);
        } else if (res.subject === "NotAuthenticated") {
          props.history.push("/notloggedin");
        } else if (res.subject === "NotAuthorized") {
          props.history.push("/notauthorized");
        }
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="medicalrecords">
      <h1>Fallbörse</h1>
      <ul className="tableHead">
        <li className="spaceOne">Datum</li>
        <li className="spaceTwo">Adresse</li>
        <li className="spaceThree">Beschreibung</li>
        <li className="spaceFour">Aktion</li>
      </ul>
      <ul>
        {api.appointments.map((appointment, index) => (
          <AppointmentsExchange key={index} data={appointment} />
        ))}
      </ul>
    </div>
  );
};

export default withRouter(Exchange);
