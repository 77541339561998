import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../Form.css";
import "../Checkbox.css";
import useForm from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { namePattern, telPattern, emailPattern } from "../Pattern/Pattern";
import vars from "../../utils/variables";

const Register = props => {
  const [job, setJob] = useState("");
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [firm, setFirm] = useState("");
  const [firmHide, setFirmHide] = useState(false);
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [bdate, setBirthdate] = useState(null);
  const [error, setError] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm();

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const handleFetch = () => {
    let birthdate;
    if (bdate != null) {
      birthdate = bdate.getTime() / 1000;
    } else {
      birthdate = "";
    }

    fetch(`${vars.server}/users/register`, {
      method: "POST",
      body: JSON.stringify({
        job,
        gender,
        firstname,
        lastname,
        firm,
        tel,
        email,
        password,
        confirmationPassword,
        birthdate
      }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          props.history.push("/registersuccess");
        } else {
          setError(result.message);
        }
      });
  };

  const handleChangeStatus = event => {
    setJob(event.target.value);
    checkJob(event.target.value);
  };
  const handleChangeGender = event => setGender(event.target.value);
  const handleChangeFirstName = event => setFirstname(event.target.value);
  const handleChangeLastName = event => setLastname(event.target.value);
  const handleChangeFirm = event => setFirm(event.target.value);
  const handleChangeTel = event => setTel(event.target.value);
  const handleChangeEmail = event => setEmail(event.target.value);
  const handleChangePassword = event => setPassword(event.target.value);
  const handleChangeConfirmationPassword = event => setConfirmationPassword(event.target.value);

  const checkJob = async value => {
    await sleep(500);
    setFirmHide(value === "SozialarbeiterIn");
    value === "SozialarbeiterIn" ? setFirm("") : setFirm("-");
  };

  const validatePassword = async value => {
    await sleep(500);
    return value === watch("namePassword1");
  };

  return props.auth ? (
    <div>{props.history.push("/dashboard")}</div>
  ) : (
    <form onSubmit={handleSubmit(handleFetch)} className="formForm">
      <h1>Registrieren</h1>
      {error && <p className="errorMessage">Fehler: {error}</p>}
      <label className="labelRL">
        Registrieren als*
        <select
          defaultValue={job}
          onChange={handleChangeStatus}
          name="nameJob"
          ref={register({ required: true })}
          className="inputRL"
        >
          <option value="">-</option>
          <option value="SozialarbeiterIn">SozialarbeiterIn</option>
          <option value="DolmetscherIn">DolmetscherIn</option>
          <option value="MedizinerIn">MedizinerIn</option>
        </select>
      </label>
      {errors.nameJob && <p className="error">Pflichtfeld</p>}
      <label className="labelRL">
        Gender*
        <select
          defaultValue={gender}
          onChange={handleChangeGender}
          name="nameGender"
          ref={register({ required: true })}
          className="inputRL"
        >
          <option value="">-</option>
          <option value="m">Männlich</option>
          <option value="w">Weiblich</option>
          <option value="d">Diverse</option>
        </select>
      </label>
      {errors.nameGender && <p className="error">Pflichtfeld</p>}
      <label className="labelRL">
        Vorname*
        <input
          type="text"
          defaultValue={firstname}
          onChange={handleChangeFirstName}
          name="nameFirstName"
          ref={register({
            required: true,
            pattern: namePattern
          })}
          className="inputRL"
          placeholder="Vorname"
        />
      </label>
      {errors.nameFirstName && errors.nameFirstName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameFirstName && errors.nameFirstName.type === "pattern" && <p className="error">Invalider Vorname</p>}
      <label className="labelRL">
        Nachname*
        <input
          type="text"
          defaultValue={lastname}
          onChange={handleChangeLastName}
          name="nameLastName"
          ref={register({
            required: true,
            pattern: namePattern
          })}
          className="inputRL"
          placeholder="Nachname"
        />
      </label>
      {errors.nameLastName && errors.nameLastName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameLastName && errors.nameLastName.type === "pattern" && <p className="error">Invalider Nachname</p>}
      <label className="labelRL">
        Geburtsdatum*
        <br />
        <DatePicker
          selected={bdate}
          onChange={date => setBirthdate(date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="tt/mm/jjjj"
          required
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="inputRL date"
        />
      </label>
      <label className={!firmHide ? "labelRL labelHidden" : "labelRL"}>
        Firma/Institution*
        <input
          type="text"
          defaultValue={firm}
          onChange={handleChangeFirm}
          name="nameFirm"
          ref={register({ required: firmHide })}
          className="inputRL"
          hidden={!firmHide}
          placeholder="Firma/Institution"
        />
      </label>
      {errors.nameFirm && <p className="error">Pflichtfeld</p>}
      <label className="labelRL">
        Telefon*
        <input
          type="tel"
          defaultValue={tel}
          onChange={handleChangeTel}
          name="nameTel"
          ref={register({
            required: true,
            pattern: telPattern
          })}
          className="inputRL"
          placeholder="Telefonnummer"
        />
      </label>
      {errors.nameTel && errors.nameTel.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.nameTel && errors.nameTel.type === "pattern" && <p className="error">Invalide Telefonnummer</p>}
      <label className="labelRL">
        Email*
        <input
          type="text"
          defaultValue={email}
          onChange={handleChangeEmail}
          name="emailName"
          ref={register({
            required: true,
            pattern: emailPattern
          })}
          className="inputRL"
          placeholder="max.mustermann@mail.de"
        />
      </label>
      {errors.emailName && errors.emailName.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.emailName && errors.emailName.type === "pattern" && <p className="error">Invalide Mail Adresse</p>}
      <label className="labelRL">
        Passwort*
        <input
          type="password"
          defaultValue={password}
          onChange={handleChangePassword}
          name="namePassword1"
          ref={register({ required: true, minLength: 10 })}
          className="inputRL"
          placeholder="Passwort"
        />
      </label>
      {errors.namePassword1 && errors.namePassword1.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.namePassword1 && errors.namePassword1.type === "minLength" && (
        <p className="error">Passwort muss mindestens 10 Zeichen lang sein</p>
      )}
      <label className="labelRL">
        Passwort bestätigen*
        <input
          type="password"
          defaultValue={confirmationPassword}
          onChange={handleChangeConfirmationPassword}
          name="namePassword2"
          ref={register({
            required: true,
            validate: validatePassword
          })}
          className="inputRL"
          placeholder="Passwort wiederholen"
        />
      </label>
      {errors.namePassword2 && errors.namePassword2.type === "required" && <p className="error">Pflichtfeld</p>}
      {errors.namePassword2 && errors.namePassword2.type === "validate" && (
        <p className="error">Passwörter stimmen nicht überein</p>
      )}
      <br />
      <br />
      <label className="labelRL container">
        <input type="checkbox" name="nameAGB" ref={register({ required: true })} />
        Hiermit akzeptiere ich die <strong>Nutzungsbedingungen</strong> (AGB)*
        <span className="checkmark"></span>
      </label>
      {errors.nameAGB && <p className="error">Bitte akzeptieren Sie die AGB</p>}
      <label className="labelRL container">
        <input
          type="checkbox"
          name="nameDSB"
          ref={register({
            required: true
          })}
        />
        Hiermit akzeptiere ich die <strong>Datenschutzbestimmungen</strong>*<span className="checkmark"></span>
      </label>
      {errors.nameDSB && <p className="error">Bitte akzeptieren Sie die Datenschutzbestimmungen</p>}
      <input type="submit" name="nameSubmit" value="Registrieren" className="inputRLSubmit" />
    </form>
  );
};

export default withRouter(Register);
