import React, { Component } from "react";

class CountryOptions extends Component {
  render() {
    return (
      <select
        defaultValue={this.props.country}
        onChange={this.props.handleChangeCountry}
        name="nameCountry"
        className="inputRL"
      >
        <option value="">-</option>
        <option value="Afghanistan">Afghanistan (Afghanistan)</option>
        <option value="Ägypten">Ägypten (Egypt)</option>
        <option value="Aland">Aland (Åland Islands)</option>
        <option value="Albanien">Albanien (Albania)</option>
        <option value="Algerien">Algerien (Algeria)</option>
        <option value="Amerikanisch-Samoa">Amerikanisch-Samoa (American Samoa)</option>
        <option value="Amerikanische Jungferninseln">Amerikanische Jungferninseln (Virgin Islands, U.s.)</option>
        <option value="Andorra">Andorra (Andorra)</option>
        <option value="Angola">Angola (Angola)</option>
        <option value="Anguilla">Anguilla (Anguilla)</option>
        <option value="Antarktis">Antarktis (Antarctica)</option>
        <option value="Antigua und Barbuda">Antigua und Barbuda (Antigua And Barbuda)</option>
        <option value="Äquatorialguinea">Äquatorialguinea (Equatorial Guinea)</option>
        <option value="Argentinien">Argentinien (Argentina)</option>
        <option value="Armenien">Armenien (Armenia)</option>
        <option value="Aruba">Aruba (Aruba)</option>
        <option value="Ascension">Ascension (Ascension)</option>
        <option value="Aserbaidschan">Aserbaidschan (Azerbaijan)</option>
        <option value="Äthiopien">Äthiopien (Ethiopia)</option>
        <option value="Australien">Australien (Australia)</option>
        <option value="Bahamas">Bahamas (Bahamas)</option>
        <option value="Bahrain">Bahrain (Bahrain)</option>
        <option value="Bangladesch">Bangladesch (Bangladesh)</option>
        <option value="Barbados">Barbados (Barbados)</option>
        <option value="Belgien">Belgien (Belgium)</option>
        <option value="Belize">Belize (Belize)</option>
        <option value="Benin">Benin (Benin)</option>
        <option value="Bermuda">Bermuda (Bermuda)</option>
        <option value="Bhutan">Bhutan (Bhutan)</option>
        <option value="Bolivien">Bolivien (Bolivia)</option>
        <option value="Bosnien und Herzegowina">Bosnien und Herzegowina (Bosnia And Herzegovina)</option>
        <option value="Botswana">Botswana (Botswana)</option>
        <option value="Bouvetinsel">Bouvetinsel (Bouvet Island)</option>
        <option value="Brasilien">Brasilien (Brazil)</option>
        <option value="Brunei">Brunei (Brunei Darussalam)</option>
        <option value="Bulgarien">Bulgarien (Bulgaria)</option>
        <option value="Burkina Faso">Burkina Faso (Burkina Faso)</option>
        <option value="Burundi">Burundi (Burundi)</option>
        <option value="Chile">Chile (Chile)</option>
        <option value="China">China (China)</option>
        <option value="Cookinseln">Cookinseln (Cook Islands)</option>
        <option value="Costa Rica">Costa Rica (Costa Rica)</option>
        <option value="Cote d'Ivoire">Cote d'Ivoire (CÔte D'ivoire)</option>
        <option value="Dänemark">Dänemark (Denmark)</option>
        <option value="Deutschland">Deutschland (Germany)</option>
        <option value="Diego Garcia">Diego Garcia (Diego Garcia)</option>
        <option value="Dominica">Dominica (Dominica)</option>
        <option value="Dominikanische Republik">Dominikanische Republik (Dominican Republic)</option>
        <option value="Dschibuti">Dschibuti (Djibouti)</option>
        <option value="Ecuador">Ecuador (Ecuador)</option>
        <option value="El Salvador">El Salvador (El Salvador)</option>
        <option value="Eritrea">Eritrea (Eritrea)</option>
        <option value="Estland">Estland (Estonia)</option>
        <option value="Europäische Union">Europäische Union (Europäische Union)</option>
        <option value="Falklandinseln">Falklandinseln (Falkland Islands (malvinas))</option>
        <option value="Färöer">Färöer (Faroe Islands)</option>
        <option value="Fidschi">Fidschi (Fiji)</option>
        <option value="Finnland">Finnland (Finland)</option>
        <option value="Frankreich">Frankreich (France)</option>
        <option value="Französisch-Guayana">Französisch-Guayana (French Guiana)</option>
        <option value="Französisch-Polynesien">Französisch-Polynesien (French Polynesia)</option>
        <option value="Gabun">Gabun (Gabon)</option>
        <option value="Gambia">Gambia (Gambia)</option>
        <option value="Georgien">Georgien (Georgia)</option>
        <option value="Ghana">Ghana (Ghana)</option>
        <option value="Gibraltar">Gibraltar (Gibraltar)</option>
        <option value="Grenada">Grenada (Grenada)</option>
        <option value="Griechenland">Griechenland (Greece)</option>
        <option value="Grönland">Grönland (Greenland)</option>
        <option value="Großbritannien">Großbritannien (Create Britain)</option>
        <option value="Guadeloupe">Guadeloupe (Guadeloupe)</option>
        <option value="Guam">Guam (Guam)</option>
        <option value="Guatemala">Guatemala (Guatemala)</option>
        <option value="Guernsey">Guernsey (Guernsey)</option>
        <option value="Guinea">Guinea (Guinea)</option>
        <option value="Guinea-Bissau">Guinea-Bissau (Guinea-bissau)</option>
        <option value="Guyana">Guyana (Guyana)</option>
        <option value="Haiti">Haiti (Haiti)</option>
        <option value="Heard und McDonaldinseln">Heard und McDonaldinseln (Heard Island And Mcdonald Islands)</option>
        <option value="Honduras">Honduras (Honduras)</option>
        <option value="Hongkong">Hongkong (Hong Kong)</option>
        <option value="Indien">Indien (India)</option>
        <option value="Indonesien">Indonesien (Indonesia)</option>
        <option value="Irak">Irak (Iraq)</option>
        <option value="Iran">Iran (Iran, Islamic Republic Of)</option>
        <option value="Irland">Irland (Ireland)</option>
        <option value="Island">Island (Iceland)</option>
        <option value="Israel">Israel (Israel)</option>
        <option value="Italien">Italien (Italy)</option>
        <option value="Jamaika">Jamaika (Jamaica)</option>
        <option value="Japan">Japan (Japan)</option>
        <option value="Jemen">Jemen (Yemen)</option>
        <option value="Jersey">Jersey (Jersey)</option>
        <option value="Jordanien">Jordanien (Jordan)</option>
        <option value="Kaimaninseln">Kaimaninseln (Cayman Islands)</option>
        <option value="Kambodscha">Kambodscha (Cambodia)</option>
        <option value="Kamerun">Kamerun (Cameroon)</option>
        <option value="Kanada">Kanada (Canada)</option>
        <option value="Kanarische Inseln">Kanarische Inseln (Kanarische Inseln)</option>
        <option value="Kap Verde">Kap Verde (Cape Verde)</option>
        <option value="Kasachstan">Kasachstan (Kazakhstan)</option>
        <option value="Katar">Katar (Qatar)</option>
        <option value="Kenia">Kenia (Kenya)</option>
        <option value="Kirgisistan">Kirgisistan (Kyrgyzstan)</option>
        <option value="Kiribati">Kiribati (Kiribati)</option>
        <option value="Kokosinseln">Kokosinseln (Cocos (keeling) Islands)</option>
        <option value="Kolumbien">Kolumbien (Colombia)</option>
        <option value="Komoren">Komoren (Comoros)</option>
        <option value="Kongo">Kongo (Congo)</option>
        <option value="Kroatien">Kroatien (Croatia)</option>
        <option value="Kuba">Kuba (Cuba)</option>
        <option value="Kuwait">Kuwait (Kuwait)</option>
        <option value="Laos">Laos (Lao People's Democratic Republic)</option>
        <option value="Lesotho">Lesotho (Lesotho)</option>
        <option value="Lettland">Lettland (Latvia)</option>
        <option value="Libanon">Libanon (Lebanon)</option>
        <option value="Liberia">Liberia (Liberia)</option>
        <option value="Libyen">Libyen (Libyan Arab Jamahiriya)</option>
        <option value="Liechtenstein">Liechtenstein (Liechtenstein)</option>
        <option value="Litauen">Litauen (Lithuania)</option>
        <option value="Luxemburg">Luxemburg (Luxembourg)</option>
        <option value="Macao">Macao (Macao)</option>
        <option value="Madagaskar">Madagaskar (Madagascar)</option>
        <option value="Malawi">Malawi (Malawi)</option>
        <option value="Malaysia">Malaysia (Malaysia)</option>
        <option value="Malediven">Malediven (Maldives)</option>
        <option value="Mali">Mali (Mali)</option>
        <option value="Malta">Malta (Malta)</option>
        <option value="Marokko">Marokko (Morocco)</option>
        <option value="Marshallinseln">Marshallinseln (Marshall Islands)</option>
        <option value="Martinique">Martinique (Martinique)</option>
        <option value="Mauretanien">Mauretanien (Mauritania)</option>
        <option value="Mauritius">Mauritius (Mauritius)</option>
        <option value="Mayotte">Mayotte (Mayotte)</option>
        <option value="Mazedonien">Mazedonien (Macedonia, The Former Yugoslav Republic Of)</option>
        <option value="Mexiko">Mexiko (Mexico)</option>
        <option value="Mikronesien">Mikronesien (Micronesia)</option>
        <option value="Moldawien">Moldawien (Moldova)</option>
        <option value="Monaco">Monaco (Monaco)</option>
        <option value="Mongolei">Mongolei (Mongolia)</option>
        <option value="Montserrat">Montserrat (Montserrat)</option>
        <option value="Mosambik">Mosambik (Mozambique)</option>
        <option value="Myanmar">Myanmar (Myanmar)</option>
        <option value="Namibia">Namibia (Namibia)</option>
        <option value="Nauru">Nauru (Nauru)</option>
        <option value="Nepal">Nepal (Nepal)</option>
        <option value="Neukaledonien">Neukaledonien (New Caledonia)</option>
        <option value="Neuseeland">Neuseeland (New Zealand)</option>
        <option value="Neutrale Zone">Neutrale Zone (Neutrale Zone)</option>
        <option value="Nicaragua">Nicaragua (Nicaragua)</option>
        <option value="Niederlande">Niederlande (Netherlands)</option>
        <option value="Niederländische Antillen">Niederländische Antillen (Netherlands Antilles)</option>
        <option value="Niger">Niger (Niger)</option>
        <option value="Nigeria">Nigeria (Nigeria)</option>
        <option value="Niue">Niue (Niue)</option>
        <option value="Nordkorea">Nordkorea (North Korea)</option>
        <option value="Nördliche Marianen">Nördliche Marianen (Northern Mariana Islands)</option>
        <option value="Norfolkinsel">Norfolkinsel (Norfolk Island)</option>
        <option value="Norwegen">Norwegen (Norway)</option>
        <option value="Oman">Oman (Oman)</option>
        <option value="Österreich">Österreich (Austria)</option>
        <option value="Pakistan">Pakistan (Pakistan)</option>
        <option value="Palästina">Palästina (Palestinian Territory)</option>
        <option value="Palau">Palau (Palau)</option>
        <option value="Panama">Panama (Panama)</option>
        <option value="Papua-Neuguinea">Papua-Neuguinea (Papua New Guinea)</option>
        <option value="Paraguay">Paraguay (Paraguay)</option>
        <option value="Peru">Peru (Peru)</option>
        <option value="Philippinen">Philippinen (Philippines)</option>
        <option value="Pitcairninseln">Pitcairninseln (Pitcairn)</option>
        <option value="Polen">Polen (Poland)</option>
        <option value="Portugal">Portugal (Portugal)</option>
        <option value="Puerto Rico">Puerto Rico (Puerto Rico)</option>
        <option value="Réunion">Réunion (RÉunion)</option>
        <option value="Ruanda">Ruanda (Rwanda)</option>
        <option value="Rumänien">Rumänien (Romania)</option>
        <option value="Russische Föderation">Russische Föderation (Russian Federation)</option>
        <option value="Salomonen">Salomonen (Solomon Islands)</option>
        <option value="Sambia">Sambia (Zambia)</option>
        <option value="Samoa">Samoa (Samoa)</option>
        <option value="San Marino">San Marino (San Marino)</option>
        <option value="São Tomé und Príncipe">São Tomé und Príncipe (Sao Tome And Principe)</option>
        <option value="Saudi-Arabien">Saudi-Arabien (Saudi Arabia)</option>
        <option value="Schweden">Schweden (Sweden)</option>
        <option value="Schweiz">Schweiz (Switzerland)</option>
        <option value="Senegal">Senegal (Senegal)</option>
        <option value="Serbien und Montenegro">Serbien und Montenegro (Serbien und Montenegro)</option>
        <option value="Seychellen">Seychellen (Seychelles)</option>
        <option value="Sierra Leone">Sierra Leone (Sierra Leone)</option>
        <option value="Simbabwe">Simbabwe (Zimbabwe)</option>
        <option value="Singapur">Singapur (Singapore)</option>
        <option value="Slowakei">Slowakei (Slovakia)</option>
        <option value="Slowenien">Slowenien (Slovenia)</option>
        <option value="Somalia">Somalia (Somalia)</option>
        <option value="Spanien">Spanien (Spain)</option>
        <option value="Sri Lanka">Sri Lanka (Sri Lanka)</option>
        <option value="St. Helena">St. Helena (Saint Helena)</option>
        <option value="St. Kitts und Nevis">St. Kitts und Nevis (Saint Kitts And Nevis)</option>
        <option value="St. Lucia">St. Lucia (Saint Lucia)</option>
        <option value="St. Pierre und Miquelon">St. Pierre und Miquelon (Saint Pierre And Miquelon)</option>
        <option value="St. Vincent/Grenadinen (GB)">St. Vincent/Grenadinen (GB) (Saint Vincent/Grenadines)</option>
        <option value="Südafrika, Republik">Südafrika, Republik (South Africa)</option>
        <option value="Sudan">Sudan (Sudan)</option>
        <option value="Südkorea">Südkorea (South Korea)</option>
        <option value="Suriname">Suriname (Suriname)</option>
        <option value="Svalbard und Jan Mayen">Svalbard und Jan Mayen (Svalbard And Jan Mayen)</option>
        <option value="Swasiland">Swasiland (Swaziland)</option>
        <option value="Syrien">Syrien (Syrian Arab Republic)</option>
        <option value="Tadschikistan">Tadschikistan (Tajikistan)</option>
        <option value="Taiwan">Taiwan (Taiwan, Province Of China)</option>
        <option value="Tansania">Tansania (Tanzania)</option>
        <option value="Thailand">Thailand (Thailand)</option>
        <option value="Timor-Leste">Timor-Leste (Timor-leste)</option>
        <option value="Togo">Togo (Togo)</option>
        <option value="Tokelau">Tokelau (Tokelau)</option>
        <option value="Tonga">Tonga (Tonga)</option>
        <option value="Trinidad und Tobago">Trinidad und Tobago (Trinidad And Tobago)</option>
        <option value="Tristan da Cunha">Tristan da Cunha (Tristan da Cunha)</option>
        <option value="Tschad">Tschad (Chad)</option>
        <option value="Tschechische Republik">Tschechische Republik (Czech Republic)</option>
        <option value="Tunesien">Tunesien (Tunisia)</option>
        <option value="Türkei">Türkei (Turkey)</option>
        <option value="Turkmenistan">Turkmenistan (Turkmenistan)</option>
        <option value="Turks- und Caicosinseln">Turks- und Caicosinseln (Turks And Caicos Islands)</option>
        <option value="Tuvalu">Tuvalu (Tuvalu)</option>
        <option value="Uganda">Uganda (Uganda)</option>
        <option value="Ukraine">Ukraine (Ukraine)</option>
        <option value="Ungarn">Ungarn (Hungary)</option>
        <option value="Uruguay">Uruguay (Uruguay)</option>
        <option value="Usbekistan">Usbekistan (Uzbekistan)</option>
        <option value="Vanuatu">Vanuatu (Vanuatu)</option>
        <option value="Vatikanstadt">Vatikanstadt (Holy See (vatican City State))</option>
        <option value="Venezuela">Venezuela (Venezuela)</option>
        <option value="Vereinigte Arabische Emirate">Vereinigte Arabische Emirate (United Arab Emirates)</option>
        <option value="Vereinigte Staaten von Amerika">Vereinigte Staaten von Amerika (United States)</option>
        <option value="Vietnam">Vietnam (Viet Nam)</option>
        <option value="Wallis und Futuna">Wallis und Futuna (Wallis And Futuna)</option>
        <option value="Weihnachtsinsel">Weihnachtsinsel (Christmas Island)</option>
        <option value="Weißrussland">Weißrussland (Belarus)</option>
        <option value="Westsahara">Westsahara (Western Sahara)</option>
        <option value="Zentralafrikanische Republik">Zentralafrikanische Republik (Central African Republic)</option>
        <option value="Zypern">Zypern (Cyprus)</option>
      </select>
    );
  }
}

export default CountryOptions;
